<template>
  <div class="optimize">
    <div class="searchCondition">
      <div class="searchConditionItem">
        实 例：
        <a-select
          showSearch
          v-model="instanceId"
          placeholder="可搜索IP地址"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleInstanceListSearch"
          @change="handleInstanceListChange"
          allowClear
        >
          <a-select-option v-for="d in instanceList" :key="d.id">
            {{ d.name }}({{ d.host }}:{{ d.port }})
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        数据库名：
        <a-select
          showSearch
          v-model="databaseName"
          placeholder="请选择数据库"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="databaseNameSearch"
          allowClear
          :disabled="!instanceId"
        >
          <a-select-option v-for="d in customerList" :key="d.value">{{
            d.label
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        表名：
        <a-input
          v-model.trim="tableName"
          placeholder="请输入表名"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
          :disabled="!databaseName"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          v-model="text"
          @change="enabledChange(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title"
            >确认是否删除{{ record.instanceName }}</template
          >
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="600px"
    >
      <a-form>
        <a-form-item
          label="选择实例:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          required
        >
          <a-select
            showSearch
            v-model="actionForm.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            allowClear
            :disabled="this.title === '修改'"
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="数据库名:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          class="dbNameClass"
          required
        >
          <a-select
            showSearch
            v-model="actionForm.databaseName"
            placeholder="请选择数据库名称"
            allowClear
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="databaseNameSearch"
            @change="databaseChange"
            :disabled="this.title === '修改' || !actionForm.instanceId"
          >
            <a-select-option v-for="d in customerList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="选择表名:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          required
        >
          <a-select
            showSearch
            v-model="actionForm.tableNameList"
            placeholder="请选择表名"
            allowClear
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="tableSearch"
            :disabled="this.title === '修改' || !actionForm.databaseName"
            mode="multiple"
          >
            <a-select-option v-for="d in tableNameList" :key="d.tableName">{{
              d.tableName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="下次执行时间:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          v-show="editShow"
        >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="nextRunTimeAdd"
            placeholder="请选择时间"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          required
        >
          <span slot="label">
            cron
            <a-tooltip overlayClassName="cronTooltip">
              <template slot="title">
                <img src="../assets/WechatIMG546.png" alt="" />
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input v-model="actionForm.cronInfo" placeholder="请输入cron" />
        </a-form-item>
        <a-form-item
          label="是否启用:"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          required
        >
          <a-switch
            :disabled="this.title === '修改'"
            v-model="actionForm.enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/optimize.js";
import { showDatabases, instanceList } from "../lib/databaseList.js";

export default {
  name: "optimize",
  data() {
    return {
      instanceIdSearch: undefined,
      title: "",
      databaseName: undefined,
      editId: "",
      instanceId: undefined,
      instanceList: [],
      customerList: [],
      systemList: [],
      tableNameList: [],
      nextRunTimeAdd: undefined,
      editShow: false,
      actionForm: {
        instanceId: undefined,
        databaseName: undefined,
        tableNameList: [],
        cronInfo: "",
        enabled: false,
      },
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "表名",
          ellipsis: true,
          dataIndex: "tableName",
        },
        {
          title: "下次运行时间",
          ellipsis: true,
          dataIndex: "nextRunTime",
        },
        {
          title: "cron",
          ellipsis: true,
          dataIndex: "cronInfo",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      tableName: "",
    };
  },
  mounted() {
    this.query(1);
    this.getInstanceList({
      pageNo: 1,
      pageSize: 1000,
    });
  },
  methods: {
    enabledChange(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
        enabled: !val.enabled,
      };
      api.enabledOptimize(data).then((res) => {
        if (res.result === 200) {
          if (val.enabled) {
            this.$message.success("禁用成功");
          } else {
            this.$message.success("启用成功");
          }
          this.query();
          this.loadingTable = false;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        databaseName: this.databaseName,
        instanceId: this.instanceId,
        tableName: this.tableName,
        preserveType: "OPTIMIZE",
      };
      this.getOptimizeList(data);
    },
    // 查询分页列表
    getOptimizeList(data) {
      this.loadingTable = true;
      api
        .optimizeList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.editShow = true;
      this.addKeyVisible = true;
      this.editId = val.id;
      this.actionForm.instanceId = val.instanceId;
      this.actionForm.databaseName = val.databaseName;
      this.actionForm.tableNameList.push(val.tableName);
      this.actionForm.cronInfo = val.cronInfo;
      this.actionForm.enabled = val.enabled;
      this.nextRunTimeAdd = val.nextRunTime;
    },
    // 新增
    add() {
      this.title = "新增";
      this.editShow = false;
      this.addKeyVisible = true;
      this.actionForm.enabled = true;
      this.actionForm.cronInfo = "0 0 0 * * *";
    },
    // 确定新增
    add_submit() {
      if (this.title === "新增") {
        let data = {
          instanceId: this.actionForm.instanceId,
          databaseName: this.actionForm.databaseName,
          tableNameList: this.actionForm.tableNameList,
          cronInfo: this.actionForm.cronInfo,
          enabled: this.actionForm.enabled,
          preserveType: "OPTIMIZE",
        };
        api.addOptimize(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        let data = {
          id: this.editId,
          cronInfo: this.actionForm.cronInfo,
          nextRunTime: this.nextRunTimeAdd,
        };
        api.editOptimize(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.editId = "";
      this.actionForm.instanceId = undefined;
      this.actionForm.databaseName = undefined;
      this.actionForm.tableNameList = [];
      this.actionForm.cronInfo = "";
      this.actionForm.enabled = false;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteOptimize(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 下拉框操作-------------------
    // 搜索实例
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.getInstanceList(data);
    },
    // 搜索数据库
    databaseNameSearch(val) {
      let data = {
        databaseName: val,
        instanceId: this.instanceIdSearch,
      };
      this.getShowDatabases(data);
    },
    // 搜索表名
    tableSearch(val) {
      this.getTableList(val);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          if (res.data) {
            let list = res.data.records;
            this.instanceList = list;
          }
        }
      });
    },
    // 查询表格
    getTableList(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        instanceId: this.actionForm.instanceId,
        databaseName: this.actionForm.databaseName,
        tableName: val,
      };
      api.tableList(data).then((res) => {
        if (res.result === 200) {
          this.tableNameList = [];
          let list = res.data.records;
          this.tableNameList = list;
        }
      });
    },
    // 选择实例
    handleInstanceListChange(val) {
      if (this.addKeyVisible === false) {
        this.instanceId = val;
        this.instanceIdSearch = val;
      } else {
        this.actionForm.instanceId = val;
        this.instanceIdSearch = val;
      }
      this.actionForm.databaseName = undefined;
      if (val === undefined) {
        this.databaseName = undefined;
        this.customerList = [];
        // this.systemList = [];
      } else {
        this.getShowDatabases({ instanceId: val });
      }
    },
    // 选择数据库名
    databaseChange() {
      this.getTableList();
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabases(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.customerList = [];
          for (let i in data) {
            if (i === "customer") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.customerList.push(param);
              });
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
