<template>
  <div class="management">
    <div class="searchCondition">
      <div class="searchConditionItem">
        数据库名：
        <a-input
          placeholder="请输入数据库名"
          v-model.trim="dbName"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        IP地址：
        <a-input
          placeholder="请输入ip地址"
          v-model.trim="host"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        端口号：
        <a-input
          placeholder="请输入端口号"
          v-model.trim="port"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        是否备份：
        <a-checkbox @change="backupsChange" />
      </div>
      <div class="searchConditionItem">
        标签：
        <i style="margin-left: 28px"></i>
        <a-select
          showSearch
          :value="valueLabel"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          placeholder="请输入关键字搜索"
          style="width: 240px"
          :filterOption="false"
          @focus="searchFocus"
          @search="fetchUserLabel"
          @change="handleChangeLabel"
          :notFoundContent="fetching ? undefined : null"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in valueLabelList" :key="d.name">{{
            d.name
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        x：
        <a-input
          placeholder="请输入x"
          v-model.trim="dbX"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        y：
        <a-input
          placeholder="请输入y"
          v-model.trim="dbY"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="tag-search">
        <a-icon type="filter" />
        <span class="title">检索项:</span>
        <div
          v-for="(tag, index) in tagListSearch"
          :key="index"
          class="tag-content"
        >
          <a-tag
            :key="tag"
            :afterClose="() => tagLabelHandleClose(tag)"
            closable
          >
            {{ tag }}
          </a-tag>
        </div>
        <a href="javascript:;" @click="clearTagList" class="clear">清除</a>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
        <a-button type="primary" @click="addDatabase" icon="plus"
          >新增账号</a-button
        >
      </div>
    </div>

    <a-tabs v-model="accountTabsKey" @change="callbackAccount">
      <a-tab-pane tab="普通账号" key="ordinary">
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(columns)"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="pagination"
          size="small"
        >
          <span slot="labelTag" slot-scope="text, record" style="width: 100%">
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{ text }}</span>
                <a
                  href="javascript:;"
                  style="margin-left: 10px"
                  @click="tagOperation(record)"
                  >编辑标签</a
                >
              </template>
              <a-icon type="tag" />
            </a-tooltip>
          </span>
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a href="javascript:;" @click="checkPassword(record)">复制密码</a>
            <a-divider type="vertical" />
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" href="#">
                复制信息 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="copyInfo(record)">密码信息</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="copyCoordinate(record)"
                    >坐标信息</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-divider type="vertical" />
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" href="#">
                其他操作 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="changePassword(record)"
                    >修改密码</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="accessStatistics(record)"
                    >访问统计</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="inspect(record)">检查</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    placement="right"
                    okText="确认"
                    cancelText="取消"
                    @confirm="deleteData(record)"
                  >
                    <template slot="title">是否删除该数据库？</template>
                    <a href="javascript:;" style="color: #ff4d4f">删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane tab="系统账号" key="system">
        <a-table
          :rowClassName="$common.rowClassColor"
          bordered
          :components="$common.getTitle(columns)"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="pagination"
          size="small"
        >
          <span slot="labelTag" slot-scope="text, record" style="width: 100%">
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{ text }}</span>
                <a
                  href="javascript:;"
                  style="margin-left: 10px"
                  @click="tagOperation(record)"
                  >编辑标签</a
                >
              </template>
              <a-icon type="tag" />
            </a-tooltip>
          </span>
          <span slot="action" slot-scope="text, record" style="width: 100%">
            <a href="javascript:;" @click="checkPassword(record)">复制密码</a>
            <a-divider type="vertical" />
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" href="#">
                复制信息 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="copyInfo(record)">密码信息</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="copyCoordinate(record)"
                    >坐标信息</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-divider type="vertical" />
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" href="#">
                其他操作 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a href="javascript:;" @click="changePassword(record)"
                    >修改密码</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="accessStatistics(record)"
                    >访问统计</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="inspect(record)">检查</a>
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="javascript:;"
                    @click="deleteData(record)"
                    style="color: #f5222d"
                    >删除</a
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>

    <!-- 编辑标签 -->
    <a-modal
      title="编辑标签"
      v-model="visibleTagOperation"
      :maskClosable="false"
      :afterClose="closeTagOperation"
      width="650px"
    >
      <div class="tag-list">
        <div
          v-for="(tag, index) in tagLabelLiat"
          :key="index"
          class="tag-content"
        >
          <a-tag :key="tag" :afterClose="() => tagHandleClose(tag)" closable>
            {{ tag }}
          </a-tag>
        </div>
      </div>
      <div class="tag-add">
        <i>绑定:</i>
        <a-button @click="existingTags">已有标签</a-button>
        <i></i>
        <a-select
          mode="multiple"
          style="width: 200px"
          v-model="existingTagsValue"
          @change="existingTagsChange"
          placeholder="选择标签"
          v-if="isExistingTagsShow"
        >
          <a-select-option v-for="i in valueLabelList" :key="i.name">
            {{ i.name }}
          </a-select-option>
        </a-select>
        <i></i>
        <a-button @click="newTag" icon="plus">新建标签</a-button>
        <div class="tag-add-label" v-if="isAddTagShow">
          <i>标签名称:</i>
          <a-input placeholder="请输入标签名称" v-model="tagName" />
          <a-button type="primary" @click="addTag">添加标签</a-button>
        </div>
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="visibleTagOperation = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addTagOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新增  -->
    <a-modal
      title="新增账号"
      v-model="visibleAdd"
      :maskClosable="false"
      :afterClose="closeAdd"
    >
      <a-form>
        <a-form-item
          label="数据库类型:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="actionForm.databaseType"
            placeholder="请选择数据库类型"
            allowClear
          >
            <a-select-option v-for="i in dataTypeList" :key="i.value">
              {{ i.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="选择实例:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            showSearch
            :value="actionForm.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            :notFoundContent="fetching ? undefined : null"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
            <a-select-option v-for="d in instanceList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="数据库名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          class="dbNameClass"
          required
        >
          <!-- <a-input placeholder="必填项" v-model="actionForm.dbName"/> -->
          <a-select v-model="actionForm.dbName" placeholder="请选择数据库名称">
            <a-select-opt-group label="customer">
              <a-select-option v-for="d in customerList" :key="d.value">{{
                d.label
              }}</a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="system">
              <a-select-option v-for="d in systemList" :key="d.value">{{
                d.label
              }}</a-select-option>
            </a-select-opt-group>
          </a-select>
          <a href="javascript:;" @click="setUserName" class="setUserName"
            >设置用户名</a
          >
        </a-form-item>
        <a-form-item
          label="用户名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          v-if="isSetUserName"
          required
        >
          <a-input placeholder="请输入用户名称" v-model="actionForm.username" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看密码  -->
    <a-modal
      title="查看密码"
      v-model="visibleCheckPassword"
      :maskClosable="false"
      :footer="null"
      :afterClose="closeCheckPassword"
    >
      <a-form>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="password" read-only />
          <a
            href="javascript:;"
            class="copy-class"
            v-clipboard:copy="password"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <a-icon type="copy" />
          </a>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 修改密码  -->
    <a-modal
      title="修改密码"
      v-model="visibleChangePassword"
      :maskClosable="false"
      :afterClose="closeChangePassword"
    >
      <a-form>
        <a-form-item
          label="新密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input
            placeholder="请输入新密码"
            v-model="newPassword"
            :type="passwordType"
            style="width: 80%"
          >
            <a-icon
              @click="changeInpuntTpye('text')"
              v-if="passwordType == 'password'"
              slot="addonAfter"
              type="eye"
            />
            <a-icon
              @click="changeInpuntTpye('password')"
              v-if="passwordType == 'text'"
              slot="addonAfter"
              type="eye-invisible"
            />
          </a-input>
          <a
            href="javascript:;"
            class="copy-class2"
            v-clipboard:copy="newPassword"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <a-icon type="copy" />
          </a>
          <a href="javascript:;" @click="randomPsw" class="randomPsw"
            >随机密码</a
          >
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="changePasswordCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="changePasswordOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 访问统计 -->
    <a-modal
      title="访问统计"
      v-model="visibleAccount"
      :maskClosable="false"
      :afterClose="closeAccount"
      :footer="null"
      width="900px"
    >
      <div id="mountNode" v-if="isAccountShow"></div>
      <a-empty v-if="isEmpty" />
    </a-modal>
  </div>
</template>

<script>
import * as api from "../lib/databaseList.js";
import G2 from "@antv/g2";
import { DataSet } from "@antv/data-set";

export default {
  name: "management",
  data() {
    return {
      dataTypeList: [
        {
          value: "REDIS",
          label: "redis",
        },
        {
          value: "MYSQL",
          label: "mysql",
        },
      ],
      dbX: "",
      dbY: "",
      accountTabsKey: "ordinary",
      systemAccount: false,
      customerList: [],
      systemList: [],
      fetching: false,
      instanceList: [],
      isSetUserName: false,
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "dbName",
        },
        {
          title: "IP地址",
          ellipsis: true,
          dataIndex: "host",
        },
        {
          title: "端口号",
          ellipsis: true,
          dataIndex: "port",
        },
        {
          title: "登录名",
          ellipsis: true,
          dataIndex: "username",
        },
        {
          title: "host",
          ellipsis: true,
          dataIndex: "allowHost",
        },
        {
          title: "是否备份",
          ellipsis: true,
          dataIndex: "backupLabel",
        },
        {
          title: "x",
          ellipsis: true,
          dataIndex: "x",
        },
        {
          title: "y",
          ellipsis: true,
          dataIndex: "y",
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "labelTag",
          scopedSlots: { customRender: "labelTag" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      host: "",
      port: "",
      dbName: "",
      needBackup: null,
      visibleCheckPassword: false,
      password: "",
      actionForm: {
        host: "",
        dbName: undefined,
        port: "3306",
        username: "",
        innerDb: false,
        instanceId: undefined,
        databaseType: undefined,
      },
      visibleAdd: false,
      loading: false,
      visibleChangePassword: false,
      newPassword: "",
      passwordType: "password",
      userId: "",
      pageNo: 1,
      id: "",
      taskId: "",
      accountList: [],
      visibleAccount: false,
      isAccountShow: false,
      isEmpty: false,
      chart: null,
      valueLabel: undefined,
      valueLabelList: [],
      tagLabelLiat: [],
      visibleTagOperation: false,
      tagName: "",
      isAddTagShow: false,
      isExistingTagsShow: false,
      existingTagsValue: [],
      tagListSearch: [],
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    // 获取
    searchFocus() {
      this.getSearchTag({});
    },
    // 搜索标签
    fetchUserLabel(val) {
      this.fetching = true;
      let data = {
        tagName: val,
      };
      this.getSearchTag(data);
    },
    // 选择标签
    handleChangeLabel(val) {
      // this.valueLabel = val;
      const tagName = val;
      let tagListSearch = this.tagListSearch;
      if (tagName && tagListSearch.indexOf(tagName) === -1) {
        tagListSearch = [...tagListSearch, tagName];
      }
      this.tagListSearch = tagListSearch;
      this.query();
    },
    // 查询标签
    getSearchTag(data) {
      api.searchTag(data).then((res) => {
        if (res.result === 200) {
          this.valueLabelList = res.data;
          this.fetching = false;
        }
      });
    },
    // 点击标签
    tagOperation(val) {
      this.visibleTagOperation = true;
      this.accountId = val.id;
      this.tagLabelLiat = val.tagList;
      this.existingTagsValue = val.tagList;
    },
    // 删除检索标签数组
    tagLabelHandleClose(removedTag) {
      const tags = this.tagListSearch.filter((tag) => tag !== removedTag);
      this.tagListSearch = tags;
      this.query();
    },
    // 点击清除检索标签
    clearTagList() {
      this.tagListSearch = [];
      this.query();
    },
    // 删除标签数组
    tagHandleClose(removedTag) {
      const tags = this.tagLabelLiat.filter((tag) => tag !== removedTag);
      this.tagLabelLiat = tags;
    },
    // 点击已有标签
    existingTags() {
      this.isExistingTagsShow = !this.isExistingTagsShow;
      this.isAddTagShow = false;
      this.getSearchTag({});
    },
    // 选择已有标签
    existingTagsChange(val) {
      this.tagLabelLiat = val;
    },
    // 点击新建标签
    newTag() {
      this.isAddTagShow = !this.isAddTagShow;
      this.isExistingTagsShow = false;
    },
    // 添加标签
    addTag() {
      const tagName = this.tagName;
      let tagLabelLiat = this.tagLabelLiat;
      if (tagName && tagLabelLiat.indexOf(tagName) === -1) {
        tagLabelLiat = [...tagLabelLiat, tagName];
      } else {
        this.$message.warning("标签已绑定");
      }
      this.tagLabelLiat = tagLabelLiat;
      this.tagName = "";
    },
    // 确定操作标签
    addTagOk() {
      let data = {
        tagNameList: this.tagLabelLiat,
        accountId: this.accountId,
      };
      this.addTagRelation(data);
    },
    // 关闭编辑标签
    closeTagOperation() {
      this.tagName = "";
      this.tagLabelLiat = [];
      this.isAddTagShow = false;
      this.isExistingTagsShow = false;
    },
    // 添加标签
    addTagRelation(data) {
      api.addTagRelation(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.visibleTagOperation = false;
          let data = {
            pageNo: this.pageNo,
            pageSize: 10,
            host: this.host,
            port: this.port,
            dbName: this.dbName,
            needBackup: this.needBackup,
            systemAccount: this.systemAccount,
            tags: this.tagListSearch.join(","),
            x: this.dbX,
            y: this.dbY,
          };
          this.getdbList(data);
        }
      });
    },
    // 点击账号列表tab
    callbackAccount(key) {
      this.pageNo = 1;
      if (key === "ordinary") {
        this.systemAccount = false;
        let data = {
          pageNo: 1,
          pageSize: 10,
          host: this.host,
          port: this.port,
          dbName: this.dbName,
          needBackup: this.needBackup,
          systemAccount: this.systemAccount,
          tags: this.tagListSearch.join(","),
          x: this.dbX,
          y: this.dbY,
        };
        this.getdbList(data);
      } else if (key === "system") {
        this.systemAccount = true;
        let data = {
          pageNo: 1,
          pageSize: 10,
          host: this.host,
          port: this.port,
          dbName: this.dbName,
          needBackup: this.needBackup,
          systemAccount: this.systemAccount,
          tags: this.tagListSearch.join(","),
          x: this.dbX,
          y: this.dbY,
        };
        this.getdbList(data);
      }
    },
    // 点击访问统计
    accessStatistics(val) {
      this.visibleAccount = true;
      this.isAccountShow = true;
      api.accountSummary({ accountId: val.id }).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.accountList = [];
          list.forEach((item) => {
            this.accountList.push({
              day: item.date,
              成功次数: parseInt(item.successCount),
              失败次数: parseInt(item.failureCount),
            });
          });
          if (this.accountList.length > 0) {
            this.drawingChart();
          } else {
            this.isEmpty = true;
          }
        }
      });
    },
    // 关闭访问统计
    closeAccount() {
      this.accountList = [];
      this.chart = null;
      this.isAccountShow = false;
      this.isEmpty = false;
    },
    // 绘制访问统计图表
    drawingChart() {
      // Step 1: 创建 Chart 对象
      this.chart = new G2.Chart({
        container: "mountNode", // 指定图表容器 ID
        // width : 600, // 指定图表宽度
        forceFit: true,
        height: 450, // 指定图表高度
        padding: [60, 60, 90, 60],
      });
      let ds = new DataSet();
      let dv = ds.createView().source(this.accountList);
      dv.transform({
        type: "fold",
        fields: ["成功次数", "失败次数"], // 展开字段集
        key: "number", // key字段
        value: "temperature", // value字段
      });
      // Step 2: 载入数据源
      this.chart.source(dv, {
        day: {
          tickCount: 8,
        },
      });
      // Step 3：创建图形语法，绘制折线图，由 day 和 value 两个属性决定图形位置，day 映射至 x 轴，value 映射至 y 轴
      this.chart.tooltip({
        crosshairs: {
          type: "line",
        },
      });
      this.chart.axis("temperature", {
        label: {
          formatter: function formatter(val) {
            return val;
          },
        },
      });
      this.chart
        .line()
        .position("day*temperature")
        .color("number", ["#00ff00", "#ff0000"]);
      this.chart
        .point()
        .position("day*temperature")
        .color("number", ["#00ff00", "#ff0000"])
        .size(4)
        .shape("circle")
        .style({
          stroke: "#fff",
          lineWidth: 1,
        });
      // Step 4: 渲染图表
      this.chart.render();
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      api.showDatabases(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.customerList = [];
          this.systemList = [];
          for (let i in data) {
            if (i === "customer") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.customerList.push(param);
              });
            }
            if (i === "system") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.systemList.push(param);
              });
            }
          }
        }
      });
    },
    // 搜索实例
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.fetching = true;
      this.getInstanceList(data);
    },
    // 选择实例
    handleInstanceListChange(val) {
      this.actionForm.instanceId = val;
      this.actionForm.dbName = undefined;
      this.getShowDatabases({ instanceId: val });
    },
    // 点击设置用户名
    setUserName() {
      this.isSetUserName = !this.isSetUserName;
      if (!this.isSetUserName) {
        this.actionForm.username = "";
      }
    },
    // 点击新增随机密码
    setPassword() {
      let psw = this.$common.randomStr(false, 12);
      this.actionForm.password = psw;
    },
    // 复制数据库密码信息
    copyInfo(val) {
      let data = {
        id: val.id,
      };
      let parameter = {
        ip: "",
        port: val.port,
        user: val.username,
        pwd: "",
      };
      // if (val.innerAccess) {
      //   parameter.ip = val.innerHost;
      // } else {
      //   parameter.ip = val.host;
      // }
      let dbName = val.dbName;
      let message = ""; // copy内容
      api
        .getDbPassword(data)
        .then((res) => {
          if (res.result === 200) {
            // if (val.innerAccess) {
            //   parameter.ip = res.data.privateAddress;
            // } else {
            //   parameter.ip = res.data.publicAddress;
            // }
            if (val.innerAccess) {
              if (res.data.privateAddress) {
                parameter.host = res.data.privateAddress;
              } else {
                parameter.host = val.innerHost;
              }
            } else {
              if (res.data.publicAddress) {
                parameter.host = res.data.publicAddress;
              } else {
                parameter.host = val.host;
              }
            }
            parameter.pwd = res.data.password;
            message = this.$common.copyInfo(parameter, dbName); // 复制数据库密码信息
            this.$copyText(message).then(
              (e) => {
                this.$message.success("复制成功");
              },
              (e) => {
                this.$message.error("复制失败");
              }
            );
          }
        })
        .catch((err) => {
          message = this.$common.copyInfo(parameter, dbName);
          this.$copyText(message).then(
            (e) => {
              // 复制成功
              this.$message.success("复制成功");
            },
            (e) => {
              // 复制失败
              this.$message.error("复制失败");
            }
          );
        });
    },
    // 复制数据库坐标信息
    copyCoordinate(val) {
      let data = {
        id: val.id,
      };
      let parameter = {
        host: "",
        port: val.port,
        userName: val.username,
        password: "",
        x: val.x,
        y: val.y,
      };
      let dbName = val.dbName;
      let message = ""; // copy内容
      api
        .getDbPassword(data)
        .then((res) => {
          if (res.result === 200) {
            if (val.innerAccess) {
              if (res.data.privateAddress) {
                parameter.host = res.data.privateAddress;
              } else {
                parameter.host = val.innerHost;
              }
            } else {
              if (res.data.publicAddress) {
                parameter.host = res.data.publicAddress;
              } else {
                parameter.host = val.host;
              }
            }
            parameter.password = res.data.password;
            message = this.$common.copyCoordinate(parameter, dbName); // 复制数据库信息
            this.$copyText(message).then(
              (e) => {
                this.$message.success("复制成功");
              },
              (e) => {
                this.$message.error("复制失败");
              }
            );
          }
        })
        .catch((err) => {});
    },
    // 点击删除
    deleteData(val) {
      api.dbDelete({ id: val.id }).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          let data = {
            pageNo: this.pageNo,
            pageSize: 10,
            host: this.host,
            port: this.port,
            dbName: this.dbName,
            needBackup: this.needBackup,
            tags: this.tagListSearch.join(","),
            x: this.dbX,
            y: this.dbY,
          };
          this.getdbList(data);
        }
      });
    },
    //随机密码
    randomPsw() {
      let psw = this.$common.randomStr(false, 12);
      this.newPassword = psw;
    },
    //密码显示/隐藏
    changeInpuntTpye(type) {
      this.passwordType = type;
    },
    // 点击检查
    inspect(val) {
      let data = {
        id: val.id,
      };
      api.dbCheck(data).then((res) => {
        if (res.result == 200) {
          this.$message.success(res.data);
        }
      });
    },
    // 查询实例
    getInstanceList(data) {
      api.instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          let list = res.data.records;
          list.forEach((item) => {
            let data = {
              label: item.host + ":" + item.port,
              value: item.id,
            };
            this.instanceList.push(data);
          });
          this.fetching = false;
        }
      });
    },
    // 点击新增
    addDatabase() {
      this.visibleAdd = true;
      let data = {
        pageNo: 1,
        pageSize: 1000,
      };
      this.getInstanceList(data);
    },
    // 确定新增
    addOk() {
      let data = {
        instanceId: this.actionForm.instanceId,
        dbName: this.actionForm.dbName,
        databaseType: this.actionForm.databaseType,
      };
      if (!data.instanceId) {
        this.$message.warning("实例不能为空");
        return;
      }
      if (!data.dbName) {
        this.$message.warning("数据库名不能为空");
        return;
      }
      if (this.isSetUserName) {
        data.username = this.actionForm.username;
      } else {
        data.username = this.actionForm.dbName;
      }
      if (!data.username) {
        this.$message.warning("用户名不能为空");
        return;
      }
      this.loading = true;
      api
        .hostAddDatabase(data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.query();
            this.addCancel();
            this.$message.success("新增成功");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 取消新增
    addCancel() {
      this.visibleAdd = false;
    },
    // 关闭新增
    closeAdd() {
      this.actionForm = {
        host: "",
        dbName: undefined,
        port: "3306",
        username: "",
        password: "",
        innerDb: false,
        instanceId: undefined,
        allowHost: "%",
        privilege: "all",
        databaseType: undefined,
      };
      this.instanceList = [];
      this.customerList = [];
      this.systemList = [];
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    //是否备份勾选
    backupsChange(e) {
      if (e.target.checked) {
        this.needBackup = true;
      } else {
        this.needBackup = null;
      }
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    //点击查询
    query() {
      this.pageNo = 1;
      let data = {
        pageNo: 1,
        pageSize: 10,
        host: this.host,
        port: this.port,
        dbName: this.dbName,
        needBackup: this.needBackup,
        systemAccount: this.systemAccount,
        tags: this.tagListSearch.join(","),
        x: this.dbX,
        y: this.dbY,
      };
      this.getdbList(data);
    },
    //查询db表数据
    getdbList(data) {
      api.dbList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.forEach((item, i) => {
            item.key = i;
            item.index = i + 1;
            if (item.needBackup) {
              item.backupLabel = "是";
            } else {
              item.backupLabel = "否";
            }
            if (item.innerDb) {
              item.innerDbLabel = "是";
            } else {
              item.innerDbLabel = "否";
            }
            item.labelTag = item.tagList.join(",");
          });
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    //分页
    changePageItem(current) {
      this.pageNo = current;
      let data = {
        pageNo: current,
        pageSize: 10,
        host: this.host,
        port: this.port,
        dbName: this.dbName,
        needBackup: this.needBackup,
        systemAccount: this.systemAccount,
        tags: this.tagListSearch.join(","),
        x: this.dbX,
        y: this.dbY,
      };
      this.getdbList(data);
    },
    // 点击查看密码
    checkPassword(val) {
      let data = {
        id: val.id,
      };
      api.getDbPassword(data).then((res) => {
        if (res.result == 200) {
          // this.visibleCheckPassword = true
          this.password = "";
          if (res.data) {
            this.password = res.data.password;
          }
          this.$copyText(this.password).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    // 关闭查看密码
    closeCheckPassword() {
      this.password = "";
    },
    // 点击修改密码
    changePassword(val) {
      this.visibleChangePassword = true;
      this.userId = val.id;
    },
    // 取消更新密码
    changePasswordCancel() {
      this.visibleChangePassword = false;
    },
    // 确定更新密码
    changePasswordOk() {
      let data = {
        id: this.userId,
        newPassword: this.newPassword,
      };
      if (!data.newPassword) {
        this.$message.warning("密码不能为空");
        return;
      }
      this.loading = true;
      api
        .resetPassword(data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.getdbList({
              pageNo: this.pageNo,
              pageSize: 10,
              host: this.host,
              port: this.port,
              dbName: this.dbName,
              needBackup: this.needBackup,
              systemAccount: this.systemAccount,
              tags: this.tagListSearch.join(","),
              x: this.dbX,
              y: this.dbY,
            });
            this.changePasswordCancel();
            this.$message.success("修改成功");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭更新密码
    closeChangePassword() {
      this.newPassword = "";
    },
  },
};
</script>

<style scoped lang="scss">
.tag-search {
  display: inline-block;
  margin: 0px 10px;
  .title {
    margin: 0px 10px;
  }
  .tag-content {
    display: inline-block;
    .ant-tag {
      width: 92%;
    }
  }
  .clear {
    margin-left: 10px;
    color: rgb(179, 174, 174);
  }
}
.tag-list {
  border: 2px dashed #dddada;
  width: 100%;
  height: 150px;
  padding: 10px;
  .tag-content {
    display: inline-block;
  }
}
.tag-add {
  margin-top: 20px;
  i {
    font-style: normal;
    margin-right: 10px;
  }
  .tag-add-label {
    display: inline-block;
    i {
      font-style: normal;
      margin: 0px 10px;
    }
    input {
      width: 130px;
      margin-right: 10px;
    }
    span {
      width: 130px;
      margin-right: 10px;
    }
  }
}
.copy-class {
  position: absolute;
  top: -9px;
  right: 13px;
  font-size: 18px;
  z-index: 3;
}
.copy-class2 {
  position: absolute;
  top: -9px;
  right: 100px;
  font-size: 18px;
  z-index: 3;
}
.randomPsw {
  margin-left: 5px;
}
</style>
<style lang="scss">
.dbNameClass {
  position: relative;
  .setUserName {
    position: absolute;
    right: -75px;
    top: -10px;
  }
  .setPassword {
    position: absolute;
    right: -65px;
    top: -10px;
  }
}
.policy-class {
  .ant-modal-body {
    padding: 0;
  }
  .switch {
    position: absolute;
    top: 61px;
    right: 20px;
    .ant-switch-checked {
      background-color: #52c41a;
    }
  }
}
.policy-tabs {
  .ant-tabs-content {
    line-height: 40px;
    .week {
      padding-left: 20px;
    }
    .ant-checkbox-group {
      .ant-checkbox-group-item {
        margin-right: 20px;
        line-height: 40px;
      }
    }
    .month {
      margin: 0 auto;
      cursor: pointer;
      &::before {
        display: inline-block;
        content: "";
        width: 80px;
        height: 20px;
      }
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        margin: 0 10px;
      }
      .span_hover:hover {
        color: #ffffff;
        background: #409eff;
        border-radius: 50%;
      }
      .span_check {
        color: #ffffff;
        background: #409eff;
        border-radius: 50%;
      }
      .span_disabled {
        color: #c0c4cc;
      }
    }
    .time {
      padding-left: 30px;
      height: 55px;
    }
    .num {
      padding-left: 30px;
      height: 55px;
      span {
        width: 128px;
      }
      input {
        width: 128px;
      }
    }
  }
}
</style>
