<template>
  <div style="height: 100%; width: 100vw" @click="isActive = false">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-header
        style="
          background: #fff;
          padding: 0 30px 0 0;
          position: fixed;
          width: 100%;
          height: 50px;
          box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);
          z-index: 999;
        "
      >
        <div class="header-cc">
          <div class="title-theme">数据库管理</div>
          <a-dropdown class="user-name-cc" style="float: right">
            <span style="cursor: pointer; padding-left: 10px; font-size: 16px">
              <a-avatar
                class="avatar"
                size="small"
                shape="circle"
                :src="loginAvatarUrl"
                v-if="loginAvatarUrl"
              />
              <span>{{ loginNickName }}</span>
            </span>
            <a-menu style="width: 150px" slot="overlay">
              <a-menu-item>
                <a
                  href="javascript:void(0)"
                  @click="logOut()"
                  style="
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                  "
                >
                  <a-icon type="poweroff" />
                  <span>退出登录</span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider
          v-model="collapsed"
          :trigger="null"
          collapsible
          :style="{
            overflow: 'auto',
            height: '100%',
            position: 'fixed',
            top: '50px',
            left: 0,
          }"
          width="180"
          theme="light"
        >
          <a-menu
            mode="inline"
            :selectedKeys="[selectedKeys]"
            :openKeys="openKeys"
            @click="selectClick"
            @openChange="onOpenChange"
          >
            <a-menu-item v-for="menu in list" :key="menu.url">
              <icon-font :type="menu.iconType" />
              <span>{{ menu.name }}</span>
            </a-menu-item>
          </a-menu>
          <div class="setting">
            <a-icon
              class="switch"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
          </div>
        </a-layout-sider>
        <a-layout
          id="HomeContent"
          :class="{
            isContent: true,
            onContentPc: isPcShow,
          }"
          :style="{ padding: '10px 16px' }"
        >
          <div style="background: rgb(255, 255, 255); padding: 24px">
            <keep-alive include="monitor">
              <router-view name="second" />
            </keep-alive>
          </div>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      routerViewShow: false,
      openKeys: [],
      list: [
        {
          name: "实例列表",
          url: "/databaseList",
          iconType: "icon-fuwushili",
        },
        {
          name: "密码管理",
          url: "/passwordManagement",
          iconType: "icon-mima",
        },
        {
          name: "sql审核列表",
          url: "/sqlAuditList",
          iconType: "icon-sql",
        },
        {
          name: "sql执行记录列表",
          url: "/userSqlLogList",
          iconType: "icon-zhihang",
        },
        {
          name: "白名单管理",
          url: "/whiteList",
          iconType: "icon-baimingdan",
        },
        {
          name: "表维护",
          url: "/tableManagement",
          iconType: "icon-shebeiweihu",
        },
      ],
      rootSubmenuKeys: [],
      loginAvatarUrl: "",
      loginNickName: "",
      isPc: false,
      isActive: false,
      collapsed: true,
    };
  },
  computed: {
    isPcShow() {
      if (this.collapsed && this.isPc) {
        return true;
      }
      return false;
    },
    selectedKeys() {
      let path = "/" + this.$route.path.split("/")[1];
      return path;
    },
  },
  watch: {
    isActive: {
      handler(newVal) {
        if (newVal) {
          this.getNoticeList();
        }
      },
    },
  },
  created() {
    window.onresize = () => {
      this.IsPC();
    };
    this.IsPC();
    this.login();
  },
  methods: {
    // 判断手机or电脑端
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPc = flag; //true为PC端，false为手机端
      //手机端默认收起菜单3
      if (!this.isPc) {
        this.collapsed = true;
      }
    },
    // 开启关闭导航栏
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    login() {
      //用户登录
      this.$http.get("/alpha/user/info").then((res) => {
        if (res.result == 200) {
          this.routerViewShow = true;
          this.loginNickName = res.userName;
          this.loginAvatarUrl = res.avatar;
          let url = window.location.href;
          url = url.split("/");
          this.list.forEach((item) => {
            this.rootSubmenuKeys.push(item.url);
          });
        }
      });
    },
    // 导航选择
    selectClick({ item, key, keyPath }) {
      let url = window.location.href;
      url = url.split("/");
      if (key === "/" + url[3]) {
        // 禁止重复跳转相同路由
        return;
      }
      this.$router.replace(key);
    },
    // 点击退出登录
    logOut() {
      this.$http.post("/alpha/user/logout").then((res) => {
        if (res.result == 200) {
          let url = window.location.href;
          url = url.split("/");
          url = url[0] + "//" + url[2];
          window.location.href = url;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
#components-layout-demo-custom-trigger {
  height: 100%;

  .avatar {
    margin-right: 10px !important;
  }

  .setting {
    position: absolute;
    bottom: 50px;
    right: 0;
    line-height: 50px;
    width: 100%;

    .switch {
      font-size: 16px;
      line-height: 30px;
      padding: 0 16px;
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
  }
  .isContent {
    margin-left: 180px;
    margin-top: 64px;

    .backGroundColor {
      background-color: #f7f7f7 !important;
    }
  }
  .onContentPc {
    margin-left: 50px;
  }
}

.title-theme {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  height: 100%;
  line-height: 50px;
  margin-left: 20px;
}

.header-cc {
  height: 50px;
  line-height: 50px;

  .user-name-cc {
    height: 100%;
    vertical-align: initial;
    min-width: 100px;

    :hover {
      background-color: #0091ff30;
    }
  }
}
.ant-menu-item-active {
  background-color: #e6f7ff5d !important;
}
.ant-menu-item {
  margin: 0 !important;
}
.ant-menu-inline-collapsed {
  width: 50px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 16px !important;
}
.ant-layout-sider-collapsed {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}
</style>
