<template>
  <div class="databaseTableCleanJob">
    <div class="searchCondition">
      <div class="searchConditionItem">
        实 例：
        <a-select
          showSearch
          v-model="instanceId"
          placeholder="可搜索IP地址"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="handleInstanceListSearch"
          @change="handleInstanceListChange"
          allowClear
        >
          <a-select-option v-for="d in instanceList" :key="d.id">
            {{ d.name }}({{ d.host }}:{{ d.port }})
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        数据库名：
        <a-select
          showSearch
          v-model="databaseName"
          placeholder="请选择数据库"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          :filterOption="false"
          @search="databaseNameSearch"
          allowClear
          :disabled="!instanceId"
        >
          <a-select-option v-for="d in customerList" :key="d.value">{{
            d.label
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        表名：
        <a-input
          v-model.trim="tableName"
          placeholder="请输入表名"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
          :disabled="!databaseName"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checkedChildren="已启用"
          unCheckedChildren="已禁用"
          v-model="text"
          @change="enabledChange(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="选择实例:"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 13 }"
        >
          <a-select
            showSearch
            v-model="instanceIdAdd"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            allowClear
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="数据库名称:"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 13 }"
        >
          <a-select
            showSearch
            v-model="databaseNameAdd"
            placeholder="请选择数据库名称"
            allowClear
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="databaseNameSearchAdd"
            @change="databaseChange"
            :disabled="!instanceIdAdd"
          >
            <a-select-option v-for="d in customerList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="数据库表名:"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 13 }"
        >
          <a-select
            showSearch
            v-model="tableNameAdd"
            placeholder="请选择数据库表名"
            allowClear
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="tableSearch"
            mode="multiple"
            :disabled="!databaseNameAdd"
          >
            <a-select-option v-for="d in tableNameList" :key="d.tableName">{{
              d.tableName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="下次执行时间:"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 13 }"
          v-show="editShow"
        >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            value-format="YYYY-MM-DD HH:mm:ss"
            v-model="nextRunTimeAdd"
            placeholder="请选择时间"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 13 }">
          <span slot="label">
            cron表达式
            <a-tooltip overlayClassName="cronTooltip">
              <template slot="title">
                <img src="../assets/WechatIMG546.png" alt="" />
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-input
            class="cron"
            v-model="cronInfoAdd"
            placeholder="请输入cron表达式"
          />
        </a-form-item>
        <a-form-item
          label="启用:"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 13 }"
        >
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="enabledAdd"
          />
        </a-form-item>
        <a-form-item
          label="保留时间:"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select
            v-if="keepSecondsShow"
            v-model="keepSecondsAdd"
            placeholder="请选择保留时间"
            allowClear
            style="width: 75%"
          >
            <a-select-option
              v-for="(item, index) in keepSecondsList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-input
            v-else
            v-model="keepSecondsInputAdd"
            placeholder="请输入保留时间"
            style="width: 75%"
          />
          <a-button
            type="link"
            style="display: inline-block"
            @click="keepSecondsShow = !keepSecondsShow"
            >{{ keepSecondsButtonText }}</a-button
          >
        </a-form-item>
        <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            保留条数
            <a-tooltip>
              <template slot="title"
                >当数据库条数小于保留条数时，将不进行清理</template
              >
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            v-if="keepMinCountShow"
            v-model="keepMinCountAdd"
            placeholder="请选择保留条数"
            allowClear
            style="width: 75%"
          >
            <a-select-option
              v-for="(item, index) in keepMinCountList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
          <a-input
            v-else
            v-model="keepMinCountInputAdd"
            placeholder="请输入保留条数"
            style="width: 75%"
          />
          <a-button
            type="link"
            style="display: inline-block"
            @click="keepMinCountShow = !keepMinCountShow"
            >{{ keepMinCountButtonText }}</a-button
          >
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/databaseTableCleanJob.js";
import { showDatabases, instanceList } from "../lib/databaseList.js";
import { tableList, enabledOptimize } from "../lib/optimize.js";

export default {
  name: "databaseTableCleanJob",
  data() {
    return {
      title: "",
      instanceId: undefined,
      instanceIdAdd: undefined,
      databaseName: undefined,
      databaseNameAdd: undefined,
      tableName: "",
      tableNameAdd: [],
      cronInfo: "",
      cronInfoAdd: "",
      enabled: undefined,
      enabledAdd: true,
      keepSeconds: undefined,
      keepSecondsAdd: "2592000",
      keepMinCount: undefined,
      keepMinCountAdd: -1,
      enabledList: [],
      groupId: "",
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      nextRunTimeAdd: undefined,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "数据库表名",
          ellipsis: true,
          dataIndex: "tableName",
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextRunTime",
        },
        {
          title: "cron表达式",
          ellipsis: true,
          dataIndex: "cronInfo",
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "保留时间",
          ellipsis: true,
          dataIndex: "keepSecondsLabel",
        },
        {
          title: "保留条数",
          ellipsis: true,
          dataIndex: "keepMinCountLabel",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      customerList: [],
      instanceList: [],
      tableNameList: [],
      editShow: false,
      keepSecondsList: [
        { label: "3天", value: "259200" },
        { label: "7天", value: "604800" },
        { label: "15天", value: "1296000" },
        { label: "一个月", value: "2592000" },
        { label: "三个月", value: "7776000" },
        { label: "六个月", value: "15552000" },
        { label: "一年", value: "31104000" },
      ],
      keepMinCountList: [{ label: "不保留", value: -1 }],
      keepSecondsButtonText: "自定义",
      keepMinCountButtonText: "自定义",
      keepSecondsShow: true,
      keepMinCountShow: true,
      keepSecondsInputAdd: "",
      keepMinCountInputAdd: "",
    };
  },
  watch: {
    keepSecondsShow: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.keepSecondsButtonText = "自定义";
        } else {
          this.keepSecondsButtonText = "选择";
        }
      },
    },
    keepMinCountShow: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.keepMinCountButtonText = "自定义";
        } else {
          this.keepMinCountButtonText = "选择";
        }
      },
    },
  },
  mounted() {
    this.query(1);
    this.handleInstanceListSearch();
  },
  methods: {
    enabledChange(val) {
      this.loadingTable = true;
      let data = {
        id: val.id,
        enabled: !val.enabled,
      };
      enabledOptimize(data).then((res) => {
        if (res.result === 200) {
          if (val.enabled) {
            this.$message.success("禁用成功");
          } else {
            this.$message.success("启用成功");
          }
          this.query();
          this.loadingTable = false;
        }
      });
    },
    databaseChange() {
      this.getTableList();
    },
    handleInstanceListChange(val) {
      this.getShowDatabases({ instanceId: val });
    },
    // 搜索表名
    tableSearch(val) {
      this.getTableList(val);
    },
    // 查询表格
    getTableList(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        instanceId: this.instanceIdAdd,
        databaseName: this.databaseNameAdd,
        tableName: val,
      };
      tableList(data).then((res) => {
        if (res.result === 200) {
          this.tableNameList = [];
          let list = res.data.records;
          this.tableNameList = list;
        }
      });
    },
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.getInstanceList(data);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          if (res.data) {
            let list = res.data.records;
            this.instanceList = list;
          }
        }
      });
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabases(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.customerList = [];
          for (let i in data) {
            if (i === "customer") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.customerList.push(param);
              });
            }
          }
        }
      });
    },
    // 搜索数据库
    databaseNameSearch(val) {
      let data = {
        databaseName: val,
        instanceId: this.instanceId,
      };
      this.getShowDatabases(data);
    },
    databaseNameSearchAdd(val) {
      let data = {
        databaseName: val,
        instanceId: this.instanceIdAdd,
      };
      this.getShowDatabases(data);
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        databaseName: this.databaseName,
        instanceId: this.instanceId,
        tableName: this.tableName,
        preserveType: "CLEAN",
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tableDataSource.forEach((item) => {
              item.extraInfo = JSON.parse(item.extraInfo);
              item.keepSeconds = item.extraInfo.keepSeconds;
              item.keepMinCount = item.extraInfo.keepMinCount;
              switch (item.keepMinCount) {
                case -1:
                  item.keepMinCountLabel = "不保留";
                  break;
                default:
                  item.keepMinCountLabel = item.keepMinCount + "条";
                  break;
              }
              switch (item.keepSeconds) {
                case "259200":
                  item.keepSecondsLabel = "3天";
                  break;
                case "604800":
                  item.keepSecondsLabel = "7天";
                  break;
                case "1296000":
                  item.keepSecondsLabel = "15天";
                  break;
                case "2592000":
                  item.keepSecondsLabel = "一个月";
                  break;
                case "7776000":
                  item.keepSecondsLabel = "三个月";
                  break;
                case "15552000":
                  item.keepSecondsLabel = "六个月";
                  break;
                case "31104000":
                  item.keepSecondsLabel = "一年";
                  break;
                default:
                  item.keepSecondsLabel = item.keepSeconds + "秒";
                  break;
              }
            });
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.editShow = true;
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.instanceIdAdd = val.instanceId;
      this.databaseNameAdd = val.databaseName;
      this.tableNameAdd.push(val.tableName);
      this.cronInfoAdd = val.cronInfo;
      this.enabledAdd = val.enabled;
      let secondFlag = this.keepSecondsList.every((item) => {
        return item.value !== val.keepSeconds;
      });
      let countFlag = this.keepMinCountList.every((item) => {
        return item.value !== val.keepMinCount;
      });
      this.keepSecondsShow = !secondFlag;
      this.keepMinCountShow = !countFlag;
      this.nextRunTimeAdd = val.nextRunTime;
      this.$nextTick(() => {
        if (this.keepSecondsShow) {
          this.keepSecondsAdd = val.keepSeconds;
        } else {
          this.keepSecondsInputAdd = val.keepSeconds;
        }
        if (this.keepMinCountShow) {
          this.keepMinCountAdd = val.keepMinCount;
        } else {
          this.keepMinCountInputAdd = val.keepMinCount;
        }
      });
    },
    // 新增
    add() {
      this.title = "新增";
      this.editShow = false;
      this.addKeyVisible = true;
      this.instanceIdAdd = undefined;
      this.databaseNameAdd = undefined;
      this.tableNameAdd.splice(0);
      this.cronInfoAdd = "0 0 0 * * *";
      this.enabledAdd = true;
      this.keepSecondsAdd = "2592000";
      this.keepMinCountAdd = -1;
    },
    // 确定新增
    add_submit() {
      let data = {
        instanceId: this.instanceIdAdd,
        databaseName: this.databaseNameAdd,
        tableNameList: this.tableNameAdd,
        cronInfo: this.cronInfoAdd,
        enabled: this.enabledAdd,
        extraInfo: JSON.stringify({
          keepSeconds: this.keepSecondsShow
            ? this.keepSecondsAdd
            : this.keepSecondsInputAdd,
          keepMinCount: this.keepMinCountShow
            ? this.keepMinCountAdd
            : this.keepMinCountInputAdd,
        }),
      };
      if (this.title === "新增") {
        (data.preserveType = "CLEAN"),
          api.addData(data).then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("添加成功");
              this.query();
            }
          });
      } else {
        data.id = this.groupId;
        data.nextRunTime = this.nextRunTimeAdd;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    // 关闭新增
    addKeyClose() {
      this.loading = false;
      this.groupId = "";
      this.instanceIdAdd = undefined;
      this.databaseNameAdd = undefined;
      this.tableNameAdd.splice(0);
      this.cronInfoAdd = "";
      this.enabledAdd = undefined;
      this.keepSecondsAdd = undefined;
      this.keepMinCountAdd = -1;
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
