import http from "./axios";

export const backupOperationPage = (data) => {
  return http.get("/backupOperation/page", data);
};

export const addBackupOperation = (data) => {
  return http.json_post("/backupOperation/add", data);
};

export const detailPage = (data) => {
  return http.get("/backupOperation/detailPage", data);
};

export const scheduledTaskStatusList = (data) => {
  return http.post("/backupOperation/scheduledTaskStatusList", data);
};

export const modifyFullSyncCronTask = (data) => {
  return http.json_post("/backupOperation/modifyFullSyncCronTask", data);
};

export const deleteBackupOperation = (data) => {
  return http.json_post("/backupOperation/delete", data);
};

export const backup = (data) => {
  return http.json_post("/backupOperation/backup", data);
};

export const updateBackupStrategy = (data) => {
  return http.json_post("/backupOperation/updateBackupStrategy", data);
};

export const backupPlanPage = (data) => {
  return http.get("/backupOperation/backupPlanPage", data);
};

export const excludeTable = (data) => {
  return http.json_post("/backupOperation/excludeTable", data);
};

export const tableList = (data) => {
  return http.get("/backupOperation/tableList", data);
};

export const recover = (data) => {
  return http.json_post("/backupOperation/recover", data);
};
