import http from "./axios";

// 查询optimize分页列表
export const optimizeList = (data) => {
  return http.get("/delivery/databaseTablePreserveJob/list", data);
};

// 新增optimize
export const addOptimize = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/add", data);
};

// 修改optimize
export const editOptimize = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/edit", data);
};

// 删除optimize
export const deleteOptimize = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/delete", data);
};

// 查询表格
export const tableList = (data) => {
  return http.get("/db/table/tableList", data);
};

// 启用禁用
export const enabledOptimize = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/enabled", data);
};
