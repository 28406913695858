<template>
  <div class="sequenceList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        实例名称：
        <a-input
          placeholder="请输入实例名称"
          v-model.trim="instanceName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        ip地址：
        <a-input
          placeholder="请输入ip地址"
          v-model.trim="host"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        url地址：
        <a-input
          placeholder="请输入url地址"
          v-model.trim="address"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        数据库名称：
        <a-input
          placeholder="请输入数据库名称"
          v-model.trim="databaseName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        周访问次数范围：
        <a-input
          placeholder="请输入周访问次数"
          v-model.trim="accessCountStart"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
        -
        <a-input
          placeholder="请输入周访问次数"
          v-model.trim="accessCountEnd"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="publicAddress" slot-scope="text, record">
        <div
          v-show="text"
          @mouseover="
            () => {
              record.oipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.oipNameShow = false;
            }
          "
        >
          <span>{{ text }} (外网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.oipNameShow"
            />
          </a-tooltip>
        </div>
        <div
          v-show="record.privateAddress"
          @mouseover="
            () => {
              record.iipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.iipNameShow = false;
            }
          "
        >
          <span>{{ record.privateAddress }} (内网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="record.privateAddress"
              v-show="record.iipNameShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="host" slot-scope="text, record">
        <div
          v-show="text"
          @mouseover="
            () => {
              record.oipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.oipNameShow = false;
            }
          "
        >
          <span>{{ text }} (外网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="text"
              v-show="record.oipNameShow"
            />
          </a-tooltip>
        </div>
        <div
          v-show="record.innerHost"
          @mouseover="
            () => {
              record.iipNameShow = true;
            }
          "
          @mouseout="
            () => {
              record.iipNameShow = false;
            }
          "
        >
          <span>{{ record.innerHost }} (内网)</span>
          <a-tooltip>
            <template slot="title"> 复制 </template>
            <a-icon
              type="copy"
              class="table-tr-btn"
              v-copy="record.innerHost"
              v-show="record.iipNameShow"
            />
          </a-tooltip>
        </div>
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">编辑</a>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      :afterClose="addKeyClose"
      class="action-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          label="外网地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="publicAddress" placeholder="请输入外网地址" />
        </a-form-item>
        <a-form-item
          label="内网地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="privateAddress" placeholder="请输入内网地址" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/sequenceList.js";

export default {
  name: "sequenceList",
  data() {
    return {
      instanceName: "",
      databaseName: "",
      host: "",
      address: "",
      accessCountStart: "",
      accessCountEnd: "",
      title: "",
      dataId: "",
      privateAddress: "",
      publicAddress: "",
      // -----------------------------
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          width: 200,
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
          width: 200,
        },
        {
          title: "url地址",
          ellipsis: true,
          dataIndex: "publicAddress",
          scopedSlots: { customRender: "publicAddress" },
        },
        {
          title: "ip地址",
          ellipsis: true,
          dataIndex: "host",
          scopedSlots: { customRender: "host" },
        },
        {
          title: "周访问次数",
          ellipsis: true,
          dataIndex: "accessCount",
          width: 90,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        instanceName: this.instanceName,
        databaseName: this.databaseName,
        host: this.host,
        address: this.address,
        accessCountStart: this.accessCountStart,
        accessCountEnd: this.accessCountEnd,
      };
      this.getDataBaseList(data);
    },
    // 查询账号列表
    getDataBaseList(data) {
      this.loadingTable = true;
      api
        .dataBaseList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              item.key = i + 1;
              item.oipNameShow = false;
              item.iipNameShow = false;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "编辑";
      this.addKeyVisible = true;
      this.dataId = val.id;
      this.privateAddress = val.privateAddress;
      this.publicAddress = val.publicAddress;
    },
    // 确定编辑
    add_submit() {
      let data = {
        id: this.dataId,
        privateAddress: this.privateAddress,
        publicAddress: this.publicAddress,
      };
      api.editDataBaseInfo(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("编辑成功");
          this.query();
        }
      });
    },
    // 关闭编辑
    addKeyClose() {
      this.loading = false;
      this.privateAddress = "";
      this.publicAddress = "";
    },
  },
};
</script>
<style lang="scss" scoped></style>
