<template>
  <a-modal
    title="备份策略"
    v-model="visiblePolicy"
    :maskClosable="false"
    :afterClose="closePolicy"
    width="400px"
    class="policy-class"
  >
    <a-tabs
      @change="callback"
      v-model="policyType"
      class="policy-tabs"
      v-if="policyTabShow"
    >
      <a-tab-pane tab="每周" key="WEEK" style="height: 250px">
        <div class="week">
          <a-checkbox-group
            :options="weekOptions"
            v-model="weekList"
            @change="onWeekChange"
          >
            <span slot="label" slot-scope="{ value }">{{ value }}</span>
          </a-checkbox-group>
        </div>
        <div class="time">
          执行时间：
          <a-time-picker
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            v-model="otherDate.weekTime"
            :allowClear="false"
          />
        </div>
        <div class="num">
          备份个数：
          <a-input placeholder="请输入备份个数" v-model="otherDate.weekNum" />
        </div>
        <div class="time">
          生效时间：
          <a-date-picker
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            v-model="otherDate.weekStartTime"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane tab="每月" key="MONTH" style="height: 330px">
        <div class="month">
          <span
            v-for="time in dateRange"
            :key="time.id"
            @click="spanCheck(time.id)"
            ref="spanCheck"
            class="span_hover"
            >{{ time.id }}</span
          >
        </div>
        <div class="time">
          执行时间：
          <a-time-picker
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            v-model="otherDate.monthTime"
            :allowClear="false"
          />
        </div>
        <div class="num">
          备份个数：
          <a-input placeholder="请输入备份个数" v-model="otherDate.monthNum" />
        </div>
        <div class="time">
          生效时间：
          <a-date-picker
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            v-model="otherDate.monthStartTime"
          />
        </div>
      </a-tab-pane>
    </a-tabs>
    <template slot="footer">
      <div style="display: flex; justify-content: center">
        <a-button key="back" @click="policyCancel">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="policyOk"
          >保存</a-button
        >
      </div>
    </template>
  </a-modal>
</template>
<script>
import * as api from "../lib/backup.js";

import moment from "moment";
export default {
  name: "policyBackup",
  props: ["data"],
  data() {
    return {
      visiblePolicy: false,
      policyType: "WEEk",
      policyTabShow: false,
      weekOptions: [
        { label: "星期一", value: "1" },
        { label: "星期二", value: "2" },
        { label: "星期三", value: "3" },
        { label: "星期四", value: "4" },
        { label: "星期五", value: "5" },
        { label: "星期六", value: "6" },
        { label: "星期日", value: "7" },
      ],
      dateRange: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
        { id: 12 },
        { id: 13 },
        { id: 14 },
        { id: 15 },
        { id: 16 },
        { id: 17 },
        { id: 18 },
        { id: 19 },
        { id: 20 },
        { id: 21 },
        { id: 22 },
        { id: 23 },
        { id: 24 },
        { id: 25 },
        { id: 26 },
        { id: 27 },
        { id: 28 },
        { id: 29 },
        { id: 30 },
        { id: 31 },
      ],
      weekList: [],
      monthList: [],
      otherDate: {
        weekTime: moment("01:00:00", "HH:mm:ss"),
        weekStartTime: undefined,
        weekNum: 3,
        monthTime: moment("01:00:00", "HH:mm:ss"),
        monthStartTime: undefined,
        monthNum: 3,
      },
      loading: false,
    };
  },
  mounted() {},
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.visiblePolicy = true;
        this.policyTabShow = true;
        this.getBackupScheduleDetail();
      });
    },
    // 查询详细信息
    getBackupScheduleDetail() {
      if (this.data.backupStrategy) {
        let backupStrategyData = JSON.parse(this.data.backupStrategy);
        this.policyType = backupStrategyData.type;
        if (this.policyType === "WEEK") {
          this.otherDate.weekStartTime = this.data.backupStartDate;
          this.weekList = backupStrategyData.date.split(",");
          this.otherDate.weekNum = backupStrategyData.maxBackupCount;
          if (backupStrategyData.time) {
            this.otherDate.weekTime = backupStrategyData.time;
          } else {
            this.otherDate.weekTime = "00:00:00";
          }
        }
        if (this.policyType === "MONTH") {
          this.otherDate.monthStartTime = this.data.backupStartDate;
          let monthList = backupStrategyData.date.split(",");
          monthList = monthList.filter((item) => item);
          this.otherDate.monthNum = backupStrategyData.maxBackupCount;
          if (backupStrategyData.time) {
            this.otherDate.monthTime = backupStrategyData.time;
          } else {
            this.otherDate.monthTime = "00:00:00";
          }
          this.$nextTick(() => {
            for (let i in monthList) {
              monthList[i] = Number(monthList[i]);
              let index = monthList[i] - 1;
              let el = this.$refs.spanCheck[index];
              el.classList.add("span_check");
            }
            this.monthList = monthList;
          });
        }
      } else {
        this.policyType = "WEEK";

        this.otherDate = {
          weekTime: "00:00:00",
          weekStartTime: undefined,
          weekNum: 3,
          monthTime: "00:00:00",
          monthStartTime: undefined,
          monthNum: 3,
        };
      }
    },
    // 切换策略备份
    callback(key) {
      this.policyType = key;
    },
    // 选择每周
    onWeekChange(val) {
      this.weekList = val;
    },
    // 选择每月
    spanCheck(val) {
      let index = val - 1,
        el = this.$refs.spanCheck[index];
      let key = this.monthList.indexOf(val);
      if (key == -1) {
        this.monthList.push(val);
        el.classList.add("span_check");
      }
      if (key > -1) {
        this.monthList.splice(key, 1);
        el.classList.remove("span_check");
      }
    },
    // 保存策略备份
    policyOk() {
      let time, maxBackupCount, date, backupStartDate;
      if (this.policyType === "WEEK") {
        time = this.otherDate.weekTime;
        backupStartDate = this.otherDate.weekStartTime;
        maxBackupCount = this.otherDate.weekNum;
        date = this.weekList.join(",");
      }
      if (this.policyType === "MONTH") {
        time = this.otherDate.monthTime;
        backupStartDate = this.otherDate.monthStartTime;
        maxBackupCount = this.otherDate.monthNum;
        date = this.monthList.join(",");
      }

      let backupStrategyData = {
        type: this.policyType,
        date: date,
        time: time,
        maxBackupCount: maxBackupCount,
      };

      let data = {
        id: this.data.id,
        backupStrategy: JSON.stringify(backupStrategyData),
        backupStartDate,
      };
      this.loading = true;
      api
        .updateBackupStrategy(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("保存成功");
            this.$emit("submit");
            this.policyCancel();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 取消策略备份
    policyCancel() {
      this.visiblePolicy = false;
    },
    // 关闭策略备份
    closePolicy() {
      this.policyTabShow = false;
      this.weekList = [];
      this.monthList = [];
      this.otherDate.weekNum = 3;
      this.otherDate.monthNum = 3;
      this.otherDate.weekTime = moment("01:00:00", "HH:mm:ss");
      this.otherDate.monthTime = moment("01:00:00", "HH:mm:ss");
      this.otherDate.weekStartTime = undefined;
      this.otherDate.monthStartTime = undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
.policy-class {
  .ant-modal-body {
    padding: 0;
  }
}
.policy-tabs {
  .ant-tabs-content {
    line-height: 40px;
    .week {
      padding-left: 20px;
    }
    .ant-checkbox-group {
      .ant-checkbox-group-item {
        margin-right: 20px;
        line-height: 40px;
      }
    }
    .month {
      margin: 0 auto;
      cursor: pointer;
      &::before {
        display: inline-block;
        content: "";
        width: 80px;
        height: 20px;
      }
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        margin: 0 10px;
      }
      .span_hover:hover {
        color: #ffffff;
        background: #409eff;
        border-radius: 50%;
      }
      .span_check {
        color: #ffffff;
        background: #409eff;
        border-radius: 50%;
      }
      .span_disabled {
        color: #c0c4cc;
      }
    }
    .time {
      padding-left: 30px;
      height: 55px;
    }
    .num {
      padding-left: 30px;
      height: 55px;
      span {
        width: 128px;
      }
      input {
        width: 128px;
      }
    }
  }
}
</style>
