<template>
  <a-modal
    title="授权管理"
    style="top: 20px"
    v-model="visible"
    :maskClosable="false"
    :afterClose="modalClose"
    width="700px"
  >
    <div>
      <div>
        用户选择：
        <a-select
          showSearch
          :value="userName"
          :defaultActiveFirstOption="false"
          :showArrow="false"
          placeholder="可以搜索试试"
          style="width: 240px"
          :filterOption="false"
          @search="userNameSelectSearch"
          @change="userNameSelectChange"
          :notFoundContent="fetching ? undefined : null"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in userNameList" :key="d.userLoginName"
            >{{ d.userName }}({{ d.userLoginName }})</a-select-option
          >
        </a-select>
      </div>
      <div class="tree_item_title" v-if="treeShow">
        <div>
          <a-checkbox @change="selectAllChange" class="select_all_checkbox">
            全选
          </a-checkbox>
        </div>
        <div>
          <div class="tree_item_radio" @click="levelAllChange('READONLY')">
            <a-radio :checked="privilege === 'READONLY'">只读</a-radio>
          </div>
          <div class="tree_item_radio" @click="levelAllChange('READ_WRITE')">
            <a-radio :checked="privilege === 'READ_WRITE'">读写</a-radio>
          </div>
          <div class="tree_item_radio" @click="levelAllChange('ALTER_TABLE')">
            <a-radio :checked="privilege === 'ALTER_TABLE'">表操作</a-radio>
          </div>
          <div class="tree_item_radio" @click="levelAllChange('ALL')">
            <a-radio :checked="privilege === 'ALL'">全部</a-radio>
          </div>
        </div>
      </div>
      <a-tree
        v-if="treeShow"
        v-model="checkedKeys"
        checkable
        :auto-expand-parent="autoExpandParent"
        :tree-data="treeData"
        :replace-fields="replaceFields"
        :selected-keys="selectedKeys"
        @expand="onExpand"
        @select="onSelect"
        class="tree_style"
      >
        <template slot="custom" slot-scope="item">
          <div class="tree_item_title">
            <div>{{ item.title }}</div>
            <div style="width: 190px">
              <div
                class="tree_item_radio"
                @click="levelChange(item, 'READONLY')"
              >
                <a-radio
                  :checked="item.privilege === 'READONLY'"
                  :disabled="radioDisabled[item.key]"
                  >只读</a-radio
                >
              </div>
              <div
                class="tree_item_radio"
                @click="levelChange(item, 'READ_WRITE')"
              >
                <a-radio
                  :checked="item.privilege === 'READ_WRITE'"
                  :disabled="radioDisabled[item.key]"
                  >读写</a-radio
                >
              </div>
              <div
                class="tree_item_radio"
                @click="levelChange(item, 'ALTER_TABLE')"
              >
                <a-radio
                  :checked="item.privilege === 'ALTER_TABLE'"
                  :disabled="radioDisabled[item.key]"
                  >表操作</a-radio
                >
              </div>
              <div
                v-if="item.table_item"
                class="tree_item_radio"
                @click="levelChange(item, 'ALL')"
              >
                <a-radio :checked="item.privilege === 'ALL'">全部</a-radio>
              </div>
            </div>
          </div>
        </template>
      </a-tree>
    </div>
    <template slot="footer">
      <div style="display: flex; justify-content: center">
        <a-button key="back" @click="empowerManageShowCancel">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="empowerManageOk"
          >确定</a-button
        >
      </div>
    </template>
  </a-modal>
</template>
<script>
import * as api from "../lib/databaseList.js";
export default {
  name: "empowerManage",
  props: ["instanceId", "databaseType"],
  data() {
    return {
      visible: false,
      treeShow: false,
      userName: undefined,
      fetching: false,
      userNameList: [],
      treeData: [],
      replaceFields: {
        children: "tableSimpleInfoList",
        title: "title",
      },
      autoExpandParent: false,
      checkedKeys: [],
      selectedKeys: [],
      loading: false,
      dbPrivilegeList: [],
      dbInfoUserName: "",
      radioDisabled: {},
      privilege: "",
    };
  },
  watch: {
    checkedKeys(val) {
      this.dbPrivilegeCreate(val);
    },
  },
  mounted() {},
  methods: {
    // 初始化
    init() {
      this.$nextTick(() => {
        this.visible = true;
        this.treeData = [];
      });
    },
    // 点击全选
    selectAllChange(e) {
      this.checkedKeys = [];
      if (e.target.checked) {
        this.treeData.forEach((item) => {
          this.checkedKeys.push(item.key);
          item.tableSimpleInfoList.forEach((menu) => {
            this.checkedKeys.push(menu.key);
          });
        });
      }
    },
    levelAllChange(sign) {
      this.privilege = sign;
      let arr = [...this.treeData];
      arr.forEach((item) => {
        this.levelChange(item, sign);
      });
    },
    // 查询实例中的数据库和表
    getDbInfoList() {
      let data = {
        instanceId: this.instanceId,
        username: this.dbInfoUserName,
        returnTableInfo: true,
      };
      this.treeData = [];
      this.checkedKeys = [];
      this.radioDisabled = {};
      this.treeShow = false;
      api.dbInfoList(data).then((res) => {
        if (res.result === 200) {
          this.treeShow = true;
          let arr = res.data;
          arr.forEach((item) => {
            item.key = item.databaseId + "-" + 0;
            item.title = item.databaseName;
            item.table_item = true;
            if (!item.privilege) {
              item.privilege = "READONLY";
            }
            item.scopedSlots = { title: "custom" };
            this.radioDisabled[item.key] = false;
            if (item.granted) {
              this.checkedKeys.push(item.key);
            }
            item.tableSimpleInfoList.forEach((childrenItem) => {
              childrenItem.key = item.databaseId + "-" + childrenItem.tableId;
              childrenItem.title = childrenItem.tableName;
              childrenItem.table_item = false;
              if (!childrenItem.privilege) {
                childrenItem.privilege = "READONLY";
              }
              childrenItem.scopedSlots = { title: "custom" };
              this.radioDisabled[childrenItem.key] = false;
              if (item.privilege === "ALL") {
                this.radioDisabled[childrenItem.key] = true;
              }
              if (childrenItem.granted) {
                this.checkedKeys.push(childrenItem.key);
              }
            });
          });
          this.treeData = arr;
        }
      });
    },
    // 提交数据生成
    dbPrivilegeCreate(val) {
      let databaseList = []; // databaseId数组
      let tableList = []; // tableId数组
      let dbPrivilegeList = []; // 实例数组
      for (let i in val) {
        let arr = val[i].split("-");
        databaseList.push(arr[0]);
        tableList.push(arr);
      }
      databaseList = this.$common.unique(databaseList);
      databaseList.forEach((item) => {
        let databaseInfo = {
          databaseId: Number(item),
          tableIdList: [],
          privilege: "",
          tableCount: 0,
        };
        this.treeData.forEach((j) => {
          if (j.databaseId == item) {
            databaseInfo.privilege = j.privilege;
            databaseInfo.tableCount = j.tableSimpleInfoList.length;
          }
        });
        tableList.forEach((menu) => {
          if (item === menu[0]) {
            if (menu[1] === "0") {
            } else {
              let privilege = "";
              this.treeData.forEach((j) => {
                j.tableSimpleInfoList.forEach((n) => {
                  if (n.tableId == menu[1]) {
                    privilege = n.privilege;
                  }
                });
              });
              databaseInfo.tableIdList.push({
                tableId: Number(menu[1]),
                privilege: privilege,
              });
            }
          }
        });
        dbPrivilegeList.push(databaseInfo);
      });
      this.dbPrivilegeList = dbPrivilegeList;
    },
    // 权限等级改变
    levelChange(item, sign) {
      let arr = item.key.split("-");
      this.treeData.forEach((menu) => {
        if (menu.key === item.key) {
          menu.privilege = sign;
          if (arr[1] === "0") {
            menu.tableSimpleInfoList.forEach((n) => {
              n.privilege = sign;
            });
          }
        }
        if (arr[1] !== "0") {
          menu.tableSimpleInfoList.forEach((n) => {
            if (n.key === item.key) {
              n.privilege = sign;
            }
          });
        }
      });
      this.treeData.forEach((j) => {
        j.tableSimpleInfoList.forEach((n) => {
          if (j.key == item.key) {
            if (sign === "ALL") {
              this.radioDisabled[n.key] = true;
            } else {
              this.radioDisabled[n.key] = false;
            }
          }
        });
      });
      this.dbPrivilegeCreate(this.checkedKeys);
      this.$forceUpdate();
    },
    // 用户选择搜索
    userNameSelectSearch(val) {
      this.fetching = true;
      this.userNameList = [];
      let data = {
        username: val,
      };
      if (!data.username) {
        this.fetching = false;
        return;
      }
      this.getUserNameList(data);
    },
    // 用户选择改变
    userNameSelectChange(val) {
      this.userName = val;
      this.checkedKeys = [];
      this.dbInfoUserName = val;
      this.getDbInfoList();
    },
    // 查询用户列表
    getUserNameList(data) {
      api.sqlUerList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.userNameList = list;
          this.fetching = false;
        }
      });
    },
    // 展开/收起节点时触发
    onExpand(expandedKeys) {
      // console.log('onExpand', expandedKeys);
      this.autoExpandParent = false;
    },
    // 点击树节点触发
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info);
      // this.selectedKeys = selectedKeys;
    },
    // 点击确定
    empowerManageOk() {
      let data = {
        instanceId: this.instanceId,
        userLoginName: this.userName,
        dbPrivilegeList: this.$common.deepClone(this.dbPrivilegeList),
        databaseType: this.databaseType,
      };
      if (!this.userName) {
        this.$message.warning("请选择用户");
        return;
      }
      this.loading = true;
      api
        .sqlGrant(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("操作成功");
            this.visible = false;
            this.$emit("submit", data);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击取消
    empowerManageShowCancel() {
      this.visible = false;
      this.$emit("cancel", false);
    },
    // 点击遮罩层或右上角叉或取消按钮的回调
    modalClose() {
      this.checkedKeys = [];
      this.treeData = [];
      this.userNameList = [];
      this.userName = undefined;
      this.dbInfoUserName = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.tree_style {
  ::v-deep .ant-tree-node-content-wrapper {
    width: 83%;
  }
}
.tree_item_title {
  display: flex;
  justify-content: space-between;
  .tree_item_radio {
    font-size: 12px;
    display: inline-block;
    .ant-radio-wrapper {
      font-size: 12px !important;
      ::v-deep .ant-radio-inner {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        &::after {
          width: 6.5px;
          height: 6.5px;
        }
      }
    }
  }
}
.select_all_checkbox {
  margin-left: 26px;
}
</style>
