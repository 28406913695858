import http from "./axios";

// 查询列表
export const queryPage = (data) => {
  return http.get("/delivery/databaseTablePreserveJob/list", data);
};

// 添加
export const addData = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/add", data);
};

// 编辑
export const editData = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/edit", data);
};

// 删除
export const deleteData = (data) => {
  return http.json_post("/delivery/databaseTablePreserveJob/delete", data);
};
