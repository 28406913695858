<template>
  <div
    @click="(contextmenuButtonShow = false), (menuShow = false)"
    style="height: 100%"
  >
    <a-dropdown
      overlayClassName="sqlDropdown"
      :trigger="['contextmenu']"
      v-model="contextmenuButtonShow"
    >
      <ux-grid
        stripe
        ref="plxTable"
        show-overflow="title"
        @table-body-scroll="scroll"
        @row-click="rowClick"
        @row-contextmenu="rowContextmenu"
        @cell-dblclick="cellDblclick"
        size="mini"
        @sort-change="sortHandleChange"
        class="editable_table_style"
        @contextmenu.native="(menuShow = true), (rowContextShow = false)"
      >
        <ux-table-column
          v-for="(item, key) in columnsList"
          :key="item.columnName"
          :field="item.columnName"
          resizable
          :title="item.columnName"
          :sortable="item.sortable"
          :remote-sort="item.sorter"
          :width="item.width"
          :min-width="item.width"
        >
          <template v-slot="scope">
            <div
              class="ellipsis_style"
              :class="[
                scope.row[item.columnName]
                  ? scope.row[item.columnName] === 'NULL'
                    ? 'td_empty'
                    : scope.row[item.columnName] === 'DEFAULT'
                    ? 'td_empty'
                    : ''
                  : 'td_empty',
                item.columnName === scope.row.edit_text[item.columnName]
                  ? 'td_yellow'
                  : '',
                item.columnName === scope.row.col ? 'td_margin' : 'td_padding',
                scope.row.delete_row ? 'td_red' : '',
                scope.row.add_row ? 'td_green' : '',
              ]"
              :title="scope.row[item.columnName]"
            >
              <a-input
                v-if="
                  item.columnName === scope.row.col &&
                  !item.is_autoComplete_show
                "
                style="padding: 0 5px"
                :value="
                  scope.row[item.columnName]
                    ? scope.row[item.columnName] === 'NULL'
                      ? ''
                      : scope.row[item.columnName] === 'DEFAULT'
                      ? ''
                      : scope.row[item.columnName]
                    : ''
                "
                @change="
                  (e) =>
                    inputHandleChange(
                      e.target.value,
                      scope.row[rowKey],
                      item.columnName
                    )
                "
                @blur="
                  blueSQL(
                    scope.row,
                    item.columnName,
                    scope.rowIndex,
                    scope.row[item.columnName]
                  )
                "
                @pressEnter.stop="
                  blueSQL(
                    scope.row,
                    item.columnName,
                    scope.rowIndex,
                    scope.row[item.columnName]
                  )
                "
                :placeholder="
                  scope.row[item.columnName] === 'NULL'
                    ? 'NULL'
                    : scope.row[item.columnName] === 'DEFAULT'
                    ? 'DEFAULT'
                    : 'EMPTY'
                "
              />
              <a-auto-complete
                v-else-if="
                  item.columnName === scope.row.col && item.is_autoComplete_show
                "
                :dataSource="autocompleteList"
                style="width: 200px"
                :value="
                  scope.row[item.columnName]
                    ? scope.row[item.columnName] === 'NULL'
                      ? ''
                      : scope.row[item.columnName] === 'DEFAULT'
                      ? ''
                      : scope.row[item.columnName]
                    : ''
                "
                :placeholder="
                  scope.row[item.columnName] === 'NULL'
                    ? 'NULL'
                    : scope.row[item.columnName] === 'DEFAULT'
                    ? 'DEFAULT'
                    : 'EMPTY'
                "
                @change="
                  (e) =>
                    inputHandleChange(e, scope.row[rowKey], item.columnName)
                "
                @blur="
                  blueSQL(
                    scope.row,
                    item.columnName,
                    scope.rowIndex,
                    scope.row[item.columnName]
                  )
                "
                :filterOption="filterOption"
              />
              <template v-else>
                <template
                  v-if="
                    item.columnName !== scope.row.col &&
                    scope.row[item.columnName] !== ''
                  "
                >
                  {{ scope.row[item.columnName] }}
                </template>
                <template v-else> EMPTY </template>
                <div
                  v-if="selectContent && item.is_select_show"
                  @click.stop="
                    selectClick($event, item, scope.row, scope.rowIndex)
                  "
                  class="up_down_style"
                >
                  <a-icon type="up" />
                  <a-icon type="down" />
                </div>
              </template>
            </div>
          </template>
        </ux-table-column>
      </ux-grid>
      <a-menu slot="overlay" style="width: 100px" v-if="menuShow">
        <a-menu-item
          @click="
            () => {
              $emit('addRow');
              menuShow = false;
            }
          "
        >
          <a-button type="link" size="small" icon="plus"> 新增 </a-button>
        </a-menu-item>
        <a-menu-item
          v-if="rowContextShow"
          @click="
            () => {
              $emit('copyRow');
              menuShow = false;
            }
          "
        >
          <a-button type="link" size="small" icon="copy"> 复制 </a-button>
        </a-menu-item>
        <a-menu-item
          v-if="rowContextShow"
          @click="
            () => {
              $emit('deleteRow');
              menuShow = false;
            }
          "
        >
          <a-button type="link" size="small" icon="delete"> 删除 </a-button>
        </a-menu-item>
        <a-menu-item
          v-if="type == 'content'"
          @click="
            () => {
              $emit('refreshContentTable');
              menuShow = false;
            }
          "
        >
          <a-button type="link" size="small" icon="sync"> 刷新 </a-button>
        </a-menu-item>
        <a-menu-item
          v-if="type == 'query' && rowContextShow"
          @click="
            () => {
              $emit('lineDetails');
              menuShow = false;
            }
          "
        >
          <a-button type="link" size="small" icon="file-search">
            单行详情
          </a-button>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
export default {
  name: "vueFastTable",
  props: {
    rowKey: {
      // 表格唯一key
      type: String,
      default: "_id_",
    },
    type: {
      type: String,
      default: "query",
    },
    columns: {
      // 表头
      type: Array,
      default: function () {
        return [];
      },
    },
    dataSource: {
      // 表数据
      type: Array,
      default: function () {
        return [];
      },
    },
    originalSqlData: {
      // 原来不变的数据
      type: Array,
      default: function () {
        return [];
      },
    },
    tableIndex: {
      // 选中行
      type: Number,
      default: -1,
    },
    userPower: {
      // 数据库操作权限
      type: Boolean,
      default: false,
    },
    clickType: {
      // 表格双击修改
      type: Boolean,
      default: false,
    },
    selectContent: {
      // 编辑开启选择模式
      type: Boolean,
      default: false,
    },
    columnOptionsMap: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editableTabsValue: {
      // 表格唯一key
      type: String,
      default: "",
    },
  },
  data() {
    return {
      columnsList: [],
      tableData: [],
      autocompleteList: [
        "int",
        "date",
        "datetime",
        "timestamp",
        "varchar",
        "char",
        "text",
        "tinyint",
        "bigint",
        "float",
        "double",
        "decimal",
      ],
      contextmenuButtonShow: false,
      menuShow: false,
      rowContextShow: true,
    };
  },
  watch: {
    // 加载表格内容
    dataSource(val) {
      this.tableData = this.$common.deepClone(this.dataSource);
      this.$refs.plxTable.loadData(this.tableData);
    },
    editableTabsValue(val) {
      this.$refs.plxTable.refreshScroll();
    },
    // 监听行数--滚动条则滚动到对应的行
    tableIndex(val) {
      this.$refs.plxTable.scrollToRow(this.tableData[val]);
    },
    columns(val) {
      let columns = this.$common.deepClone(val);
      columns.forEach((n, i) => {
        if (
          n.columnName === "is_unique" ||
          n.columnName === "index_type" ||
          n.columnName === "is_nullable" ||
          n.columnName === "extra" ||
          n.columnName === "column_default" ||
          n.columnName === "data_type"
        ) {
          n.is_select_show = true;
        } else {
          n.is_select_show = false;
        }
        if (n.columnName === "data_type") {
          n.is_autoComplete_show = true;
        } else {
          n.is_autoComplete_show = false;
        }
      });
      this.columnsList.splice(0);
      this.columnsList.push(...columns);
      this.$refs.plxTable.clearSort();
    },
  },
  mounted() {},
  methods: {
    // 当表体滚动时触发，无论横向还是竖向
    scroll({ scrollTop, scrollLeft }) {
      this.$emit("tableScroll", { scrollTop, scrollLeft });
    },
    // 当某一行被点击时会触发该事件
    rowClick(row, column, event) {
      let rowIndex = row[this.rowKey] - 1;
      let record = row;
      let data = { record, rowIndex };
      this.$emit("rowCell", data);
    },
    rowContextmenu(row, column, event) {
      this.rowContextShow = true;
      let rowIndex = row[this.rowKey] - 1;
      let record = row;
      let data = { record, rowIndex };
      this.$emit("rowCell", data);
    },
    // 当某个单元格被双击击时会触发该事件
    cellDblclick(row, column, cell, event) {
      if (this.userPower && this.clickType) {
        // 有操作权限
        if (event.target.offsetParent) {
          // 确保点击单元格
          // 防止点击边框报错
          let columnName = column.title;
          let columns = this.columns.filter((item) => {
            return item.columnName == columnName;
          });
          if (this.selectContent && columns[0].is_select_show) {
            // 开启选择模式
            return;
          } else {
            // 没有开启选择模式
            if (row.col) {
              // 防止重复点击
              return;
            }
            row.col = columnName;
            this.$forceUpdate();
            if (columns[0].is_autoComplete_show) {
              // 自动完成input
              setTimeout(() => {
                event.target.getElementsByTagName("input")[0].focus();
              }, 17);
            } else {
              // 普通input
              setTimeout(() => {
                event.target.children[0].focus();
              }, 17);
            }
          }
        }
      }
    },
    // 编辑sql窗口表格
    inputHandleChange(value, key, column) {
      let newData = [...this.tableData];
      const target = newData.filter((item) => key === item[this.rowKey])[0];
      if (target) {
        target[column] = value;
        this.tableData = newData;
      }
    },
    // 编辑框失去焦点
    blueSQL(row, col, index, text) {
      let originalSqlData = this.originalSqlData[index];
      if (originalSqlData) {
        // 判断是否是新增
        if (originalSqlData[col].toString() !== text) {
          row.edit_text[col] = col;
        } else {
          delete row.edit_text[col];
        }
        row.col = "";
      } else {
        row.col = "";
      }
      this.$forceUpdate();
      this.$emit("valueChange", this.tableData);
    },
    // 点击选择按钮
    selectClick(e, col, record, index) {
      let select_div = document.getElementById("select_content_table");
      if (select_div) {
        document.body.removeChild(select_div);
      }
      let selcetDiv = document.createElement("div");
      selcetDiv.setAttribute("id", "select_content_table");
      selcetDiv.style.width = col.width + "px";
      selcetDiv.style.left = event.clientX - col.width + 10 + "px";
      selcetDiv.style.top = event.clientY + "px";
      document.body.appendChild(selcetDiv);
      let selcetUl = document.createElement("ul");
      selcetDiv.appendChild(selcetUl);
      for (let i in this.columnOptionsMap[col.columnName]) {
        let selectLi = document.createElement("li");
        selectLi.innerHTML = this.columnOptionsMap[col.columnName][i].label;
        selcetUl.appendChild(selectLi);
        selectLi.onclick = () => {
          let originalSqlData = this.originalSqlData[index];
          if (originalSqlData) {
            // 判断是否是新增
            if (
              originalSqlData[col.columnName].toString() !==
              this.columnOptionsMap[col.columnName][i].label
            ) {
              //判断值是否修改
              record.edit_text[col.columnName] = col.columnName;
            } else {
              delete record.edit_text[col.columnName];
            }
          }
          record[col.columnName] =
            this.columnOptionsMap[col.columnName][i].value;
          selcetDiv.style.display = "none";
          this.$forceUpdate();
          this.$emit("valueChange", this.tableData);
        };
      }
    },
    // 自动补全过滤
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      );
    },
    // 分页、排序、筛选变化时触发
    sortHandleChange({ column, prop, order }) {
      let data = { column, prop, order };
      if (this.dataSource.length <= 1) {
        // 只有一条数据时无排序
        return;
      }
      this.$emit("change", data);
    },
  },
};
</script>
<style lang="scss">
.plTableBox {
  height: 100%;
  position: relative;
  .singleTable {
    height: 100%;
    .elx-table {
      height: 100%;
      .elx-table--main-wrapper {
        height: 100%;
        .elx-table--body-wrapper {
          height: calc(100% - 36px);
          color: black;
          tbody {
            .ellipsis_style {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              position: relative;
              padding: 0 10px;

              .up_down_style {
                position: absolute;
                right: 5px;
                top: 0px;
                font-size: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                color: #5b5b5b;
                cursor: pointer;
              }
            }
            .td_empty {
              color: #bfbfbf;
            }
            .td_red {
              background: rgba(255, 0, 0, 0.5);
            }
            .td_green {
              background: rgba(97, 222, 33, 0.4);
            }
            .td_yellow {
              background: #e6a23c;
            }
            .elx-cell {
              padding: 0 !important;
            }
            .elx-body--column {
              height: 20px !important;
            }
          }
        }
      }
      .elx-table--border-line {
        border-left: none !important;
        border-top: none !important;
      }
      .elx-table--empty-placeholder {
        height: 100% !important;
      }
    }
  }
}

#select_content_table {
  position: absolute;
  border: 1px solid #e1e1e1;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 6px rgb(192, 196, 204);
  border-radius: 2px;
  cursor: pointer;
  display: block;
  ul {
    margin: 0px;
    li {
      padding: 5px 10px;
      font-size: 12px;
      &:hover {
        background: #c1dbf7;
      }
    }
  }
}
</style>
