<template>
  <div class="databaseTablePreserveLog">
    <div class="searchCondition">
      <div class="searchConditionItem">
        维护类型：
        <a-select
          showSearch
          v-model="preserveType"
          placeholder="请选择维护类型"
          allowClear
          @change="query(1)"
        >
          <a-select-option
            v-for="item in preserveTypeList"
            :key="item.id"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        数据库名称：
        <a-input
          placeholder="请输入数据库名称"
          v-model.trim="databaseName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        数据库表名：
        <a-input
          placeholder="请输入数据库表名"
          v-model.trim="tableName"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
          :disabled="!databaseName"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="state" slot-scope="text, record">
        <a-tag v-if="text == 'DONE'" color="green">成功</a-tag>
        <a-tag v-else color="orange">运行中</a-tag>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/databaseTablePreserveLog.js";

export default {
  name: "databaseTablePreserveLog",
  props: ["jobId"],
  data() {
    return {
      preserveType: undefined,
      tableName: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "实例名称",
          ellipsis: true,
          dataIndex: "instanceName",
          width: 200,
        },
        {
          title: "维护类型",
          ellipsis: true,
          dataIndex: "preserveType",
          width: 100,
        },
        {
          title: "数据库名称",
          ellipsis: true,
          dataIndex: "databaseName",
          width: 200,
        },
        {
          title: "数据库表名",
          ellipsis: true,
          dataIndex: "tableName",
          width: 200,
        },
        {
          title: "处理数量",
          ellipsis: true,
          dataIndex: "count",
          width: 120,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          width: 100,
        },
        {
          title: "开始时间",
          ellipsis: true,
          dataIndex: "startAt",
          width: 170,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "time",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      preserveTypeList: [
        { id: 1, label: "表清理", value: "CLEAN" },
        { id: 2, label: "表转移", value: "TRANS" },
        { id: 3, label: "optimize", value: "OPTIMIZE" },
      ],
      databaseName: "",
    };
  },
  watch: {
    jobId: {
      handler() {
        this.query(1);
      },
    },
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        preserveType: this.preserveType,
        tableName: this.tableName,
        databaseName: this.databaseName,
        jobId: this.jobId,
      };
      this.queryPage(data);
    },
    // 查询列表
    queryPage(data) {
      this.loadingTable = true;
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              if (item.preserveType == "CLEAN") {
                item.preserveType = "表清理";
              } else if (item.preserveType == "TRANS") {
                item.preserveType = "表转移";
              } else if (item.preserveType == "OPTIMIZE") {
                item.preserveType = "optimize";
              }
              item.time = this.calculateTimeDifference(
                item.startAt,
                item.endAt
              );
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    calculateTimeDifference(startDate, endDate) {
      if (startDate == "2000-01-01 00:00:00") {
        return "00:00:00";
      } else if (!endDate || endDate == "2000-01-01 00:00:00") {
        endDate = new Date();
      } else {
        endDate = new Date(endDate);
      }
      startDate = new Date(startDate);

      // 计算差值（毫秒）
      var timeDifference = endDate - startDate;

      // 转换差值为HH:mm:ss格式
      var hours = Math.floor(timeDifference / (1000 * 60 * 60));
      var minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // 格式化输出
      var formattedDifference =
        this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);

      return formattedDifference;
    },
  },
};
</script>
