import Vue from "vue";
import VueRouter from "vue-router";
import sql from "../views/sql.vue";
import admin from "../views/admin.vue";
import databaseList from "../components/databaseList.vue";
import management from "../components/management.vue";
import sequenceList from "../components/sequenceList.vue";
import masterSlave from "../components/masterSlave.vue";
import passwordManagement from "../components/passwordManagement.vue";
import sqlAuditList from "../components/sqlAuditList.vue";
import userSqlLogList from "../components/userSqlLogList.vue";
import whiteList from "../components/whiteList.vue";
import accessLog from "../components/accessLog.vue";
import whiteIp from "../components/whiteIp.vue";
import tableManagement from "../components/tableManagement.vue";
import optimize from "../components/optimize.vue";
import databaseTableCleanJob from "../components/databaseTableCleanJob.vue";
import databaseTablePreserveLog from "../components/databaseTablePreserveLog.vue";
import databaseTableTransJob from "../components/databaseTableTransJob.vue";
import backup from "../components/backup.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/sql",
  },
  {
    path: "/sql",
    name: "sql",
    components: {
      first: sql,
    },
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/databaseList",
    components: {
      first: admin,
    },
    children: [
      {
        path: "/databaseList",
        name: "databaseList",
        components: {
          second: databaseList,
        },
        children: [
          {
            path: "/databaseList/management",
            name: "databaseListManagement",
            components: {
              three: management,
            },
          },
          {
            path: "/databaseList/sequenceList",
            name: "sequenceList",
            components: {
              three: sequenceList,
            },
          },
          {
            path: "/databaseList/masterSlave",
            name: "masterSlave",
            components: {
              three: masterSlave,
            },
          },
        ],
      },
      {
        path: "/passwordManagement",
        name: "passwordManagement",
        components: {
          second: passwordManagement,
        },
      },
      {
        path: "/sqlAuditList",
        name: "sqlAuditList",
        components: {
          second: sqlAuditList,
        },
      },
      {
        path: "/userSqlLogList",
        name: "userSqlLogList",
        components: {
          second: userSqlLogList,
        },
      },
      {
        path: "/whiteList",
        name: "whiteList",
        redirect: "/whiteList/whiteIp",
        components: {
          second: whiteList,
        },
        children: [
          {
            path: "/whiteList/accessLog",
            name: "accessLog",
            components: {
              three: accessLog,
            },
          },
          {
            path: "/whiteList/whiteIp",
            name: "whiteIp",
            components: {
              three: whiteIp,
            },
          },
        ],
      },
      {
        path: "/tableManagement",
        name: "tableManagement",
        redirect: "/tableManagement/optimize",
        components: {
          second: tableManagement,
        },
        children: [
          {
            path: "/tableManagement/optimize",
            name: "optimize",
            components: {
              three: optimize,
            },
          },
          {
            path: "/tableManagement/databaseTableCleanJob",
            name: "databaseTableCleanJob",
            components: {
              three: databaseTableCleanJob,
            },
          },
          {
            path: "/tableManagement/databaseTableTransJob",
            name: "databaseTableTransJob",
            components: {
              three: databaseTableTransJob,
            },
          },
          {
            path: "/tableManagement/databaseTablePreserveLog",
            name: "databaseTablePreserveLog",
            components: {
              three: databaseTablePreserveLog,
            },
          },
          {
            path: "/tableManagement/backup",
            name: "backup",
            components: {
              three: backup,
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
