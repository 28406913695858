import http from "./axios";

// 查询表格数据
export const whiteIpList = (data) => {
  return http.get("/db/whiteIp/list", data);
};

// 修改状态
export const changeStatus = (data) => {
  return http.json_post("/db/whiteIp/changeStatus", data);
};

// 新增数据
export const addWhiteIp = (data) => {
  return http.post("/db/whiteIp/addWhiteIp", data);
};
