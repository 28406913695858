import http from "./axios";

// 查询表格数据
export const passwordList = (data) => {
  return http.get("/db/password/list", data);
};

// 新增密码数据
export const addPassword = (data) => {
  return http.json_post("/db/password/addPassword", data);
};

// 修改密码数据
export const modifyAttribute = (data) => {
  return http.json_post("/db/password/modifyAttribute", data);
};

// 重置密码
export const modifyPassword = (data) => {
  return http.json_post("/db/password/modifyPassword", data);
};

// 查看密码
export const getPassword = (data) => {
  return http.get("/db/password/getPassword", data);
};

// 删除密码数据
export const deletePassword = (data) => {
  return http.json_post("/db/password/deletePassword", data);
};
