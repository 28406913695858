<template>
  <div class="backup">
    <div class="searchCondition">
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="name" slot-scope="text, record" style="width: 100%">
        <a-tooltip :overlayStyle="{ maxWidth: 'none' }">
          <div slot="title">
            数据库信息:<br />
            host: {{ record.databaseInstance.host }}<br />
            name: {{ record.databaseInstance.name }}<br />
          </div>
          {{ record.name }}
        </a-tooltip>
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <a-switch
          checkedChildren="开启"
          unCheckedChildren="关闭"
          :checked="record.enabled"
          @change="modifyFullSyncCronTask(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="excludeTable(record)">排除表</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="policyBackup(record)">备份策略</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="subtaskList(record, 1)">子任务列表</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="backup(record)"
        >
          <template slot="title">确认是否立即执行</template>
          <a href="javascript:;">立即执行</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="任务名称:">
          <a-input
            v-model.trim="addData.name"
            placeholder="请输入任务名称"
            allowClear
          />
        </a-form-item>
        <a-form-item label="选择实例:" required>
          <a-select
            showSearch
            v-model="addData.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
            allowClear
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库:">
          <a-select
            v-model="addData.databaseNameList"
            placeholder="请选择数据库"
            allowClear
            showSearch
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="databaseNameSearch($event, addData.instanceId)"
            mode="multiple"
            :disabled="!addData.instanceId"
          >
            <a-select-option v-for="d in customerList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <div style="margin-left: 60px">
            <div>
              <a-checkbox v-model="addData.extraInfo.drop">
                Drop table if exists
              </a-checkbox>
            </div>
            <div>
              <a-checkbox v-model="addData.extraInfo.structure">
                Include table structure
              </a-checkbox>
            </div>
            <div>
              <a-checkbox v-model="addData.extraInfo.content">
                Include table content
              </a-checkbox>
            </div>
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="子任务列表"
      v-model="subtaskListShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(subtaskTableColumns)"
        :columns="subtaskTableColumns"
        :dataSource="subtaskTableDataSource"
        :pagination="subtaskTablePagination"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag :color="record.tagColor">{{ record.statusLabel }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="detailLog(record, 1, 10)">详细记录</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="recover(record)">恢复</a>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteClick(record)"
          >
            <template slot="title">确认是否删除</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="subtaskListShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="详细记录"
      v-model="detailShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          表名：
          <a-input
            placeholder="请输入表名"
            v-model.trim="detailConditionTableName"
            @pressEnter="detailLog(null, 1)"
            @change="allowClearDetailLogChange"
            allowClear
          />
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="detailLog(null, 1)" icon="search"
            >查询</a-button
          >
          <a-button
            type="primary"
            @click="batchDownloadTable"
            :disabled="selectedRowKeys.length == 0"
          >
            批量下载
          </a-button>
          <a-button
            type="primary"
            @click="recover()"
            :disabled="selectedRowKeys.length == 0"
          >
            批量恢复
          </a-button>
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(detailTableColumns)"
        :columns="detailTableColumns"
        :dataSource="detailTableDataSource"
        :pagination="detailTablePagination"
        size="small"
        :rowKey="(record) => record.id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag :color="record.tagColor">{{ record.statusLabel }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            @click="downloadTable(record)"
            :disabled="record.status !== 'SUCCESS'"
            >下载</a
          >
          <a-divider type="vertical" />
          <a href="javascript:;" @click="recover(null, record)">恢复</a>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="detailShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      title="排除表"
      v-model="excludeTableShow"
      :maskClosable="false"
      width="800px"
    >
      <a-transfer
        :data-source="includeTableList"
        :target-keys="excludeTableList"
        :selected-keys="tableSelectKeys"
        :render="(item) => item.table"
        :listStyle="{ width: '350px', height: '300px' }"
        showSearch
        @change="handleChange"
        @selectChange="handleSelectChange"
      />
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="excludeTableShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="excludeTableSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <policy-backup ref="policyBackup" :data="data" @submit="query" />

    <a-modal
      title="恢复"
      v-model="recoverShow"
      v-if="recoverShow"
      :maskClosable="false"
      class="action-class"
      width="600px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item>
          <span slot="label">
            选择实例
            <a-tooltip>
              <template slot="title">
                如需恢复到其他数据库可以选择实例和数据库,默认为空
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-select
            showSearch
            v-model="databaseInfo.instanceId"
            placeholder="可搜索IP地址"
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="handleInstanceListSearch"
            @change="handleInstanceListChange"
          >
            <a-select-option v-for="d in instanceList" :key="d.id"
              >{{ d.name }}({{ d.host }}:{{ d.port }})</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="数据库:">
          <a-select
            v-model="databaseInfo.databaseName"
            placeholder="请选择数据库"
            showSearch
            :defaultActiveFirstOption="false"
            :showArrow="false"
            :filterOption="false"
            @search="databaseNameSearch($event, databaseInfo.instanceId)"
            :disabled="!databaseInfo.instanceId"
          >
            <a-select-option v-for="d in customerList" :key="d.value">{{
              d.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="recoverShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="recoverSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/backup.js";
import { showDatabases, instanceList } from "../lib/databaseList.js";
import policyBackup from "./policyBackup.vue";

export default {
  name: "backup",
  components: {
    policyBackup,
  },
  data() {
    return {
      databaseName: undefined,
      instanceList: [],
      customerList: [],
      addData: {
        instanceId: undefined,
        databaseNameList: [],
        extraInfo: {
          drop: true,
          structure: true,
          content: true,
        },
        name: "",
      },
      addKeyVisible: false,
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "数据库",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "上次执行时间",
          ellipsis: true,
          dataIndex: "lastBackupDate",
        },
        {
          title: "下次执行时间",
          ellipsis: true,
          dataIndex: "nextExecuteTime",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      parentId: undefined,
      taskId: undefined,
      subtaskPageNo: 1,
      subtaskTableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "timeFormat",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      subtaskTableDataSource: [],
      subtaskTablePagination: {},
      subtaskListShow: false,
      data: {},
      detailConditionTableName: "",
      detailPageNo: 1,
      detailPageSize: 10,
      detailTableColumns: [
        {
          title: "编号",
          align: "center",
          width: 100,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "数据库名",
          ellipsis: true,
          dataIndex: "databaseName",
        },
        {
          title: "表名",
          ellipsis: true,
          dataIndex: "tableName",
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          width: 80,
        },
        {
          title: "文件大小",
          ellipsis: true,
          sorter: (a, b) => a.fileSize - b.fileSize,
          dataIndex: "fileSizeFormat",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          sorter: (a, b) => a.gmtCreatedMilliSeconds - b.gmtCreatedMilliSeconds,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      detailTableDataSource: [],
      detailTablePagination: {},
      detailShow: false,
      selectedRowKeys: [],
      selectedRows: [],
      excludeTableShow: false,
      excludeTableList: [],
      includeTableList: [],
      tableSelectKeys: [],
      backUpId: "",
      databaseInfo: {
        instanceId: undefined,
        databaseName: undefined,
        excludeDatabase: true,
      },
      ids: [],
      recoverShow: false,
      instanceId: undefined,
      databaseName: undefined,
    };
  },
  mounted() {
    this.query();
    this.getInstanceList({
      pageNo: 1,
      pageSize: 1000,
    });
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.loadingTable = true;
      api
        .backupOperationPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              if (!item.status) {
                item.status = "STOP";
              }
              item.enabled = item.status == "WAITING" ? true : false;
            });
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.query(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 新增
    add() {
      this.addData = {
        instanceId: undefined,
        databaseNameList: [],
        extraInfo: {
          drop: true,
          structure: true,
          content: true,
        },
        name: "",
      };
      this.addKeyVisible = true;
    },
    excludeTable(val) {
      let data = {
        id: val.id,
      };
      api.tableList(data).then((res) => {
        if (res.result == 200) {
          let excludeTableList = [],
            includeTableList = [];
          if (val.excludeTable) {
            excludeTableList = val.excludeTable.split(",");
          }
          includeTableList = res.data;
          this.excludeTableList = excludeTableList;
          this.includeTableList = includeTableList.map((table) => {
            return { table, key: table };
          });
          this.backUpId = val.id;
          this.excludeTableShow = true;
        }
      });
    },
    handleChange(nextTargetKeys) {
      this.excludeTableList = nextTargetKeys;
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.tableSelectKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    excludeTableSubmit() {
      let data = {
        id: this.backUpId,
        tableName: this.excludeTableList.join(","),
      };
      api.excludeTable(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("操作成功");
          this.excludeTableShow = false;
          this.query();
        }
      });
    },
    policyBackup(val) {
      this.data = val;
      this.$refs.policyBackup.init();
    },
    // 搜索实例
    handleInstanceListSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        host: val,
      };
      this.getInstanceList(data);
    },
    // 搜索数据库
    databaseNameSearch(val, instanceId) {
      let data = {
        databaseName: val,
        instanceId,
      };
      this.getShowDatabases(data);
    },
    // 搜索表名
    tableSearch(val) {
      this.getTableList(val);
    },
    // 查询实例
    getInstanceList(data) {
      instanceList(data).then((res) => {
        if (res.result === 200) {
          this.instanceList = [];
          if (res.data) {
            let list = res.data.records;
            this.instanceList = list;
          }
        }
      });
    },
    // 选择实例
    handleInstanceListChange(instanceId) {
      if (!instanceId) {
        return;
      }
      let data = {
        instanceId,
      };
      this.getShowDatabases(data);
    },
    // 查询新增账号数据库
    getShowDatabases(data) {
      showDatabases(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          this.customerList = [];
          for (let i in data) {
            if (i === "customer") {
              data[i].forEach((item) => {
                let param = {
                  label: item,
                  value: item,
                };
                this.customerList.push(param);
              });
            }
          }
        }
      });
    },
    // 确定新增
    add_submit() {
      let data = { ...this.addData };
      data.databaseName = data.databaseNameList.join(",");
      data.extraInfo = JSON.stringify(data.extraInfo);
      delete data.databaseNameList;
      api.addBackupOperation(data).then((res) => {
        if (res.result === 200) {
          this.addKeyVisible = false;
          this.$message.success("添加成功");
          this.query();
        }
      });
    },
    subtaskList(record, index) {
      if (record) {
        this.parentId = record.id;
        this.instanceId = record.instanceId;
        this.databaseName = record.databaseName;
      }
      if (index) {
        this.subtaskPageNo = index;
      }
      let data = {
        parentId: this.parentId,
        pageNo: this.subtaskPageNo,
        pageSize: 10,
      };
      api.detailPage(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.fileName =
              item.databaseName + "." + item.fileType.toLowerCase();
            item.timeFormat = this.calculateTimeDifference(
              item.startDate,
              item.endDate
            );
            switch (item.status) {
              case "WAITING":
                item.statusLabel = "等待执行";
                item.tagColor = "orange";
                break;
              case "RUNNING":
                item.statusLabel = "运行中";
                item.tagColor = "orange";
                break;
              case "FAILURE":
                item.statusLabel = "失败";
                item.tagColor = "red";
                break;
              case "SUCCESS":
                item.statusLabel = "成功";
                item.tagColor = "green";
                break;
            }
          });
          this.subtaskTableDataSource = list;
          
          this.subtaskTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.subtaskList(null, current),
          };
          this.subtaskListShow = true;
        }
      });
    },
    calculateTimeDifference(startDate, endDate) {
      if (startDate == "2000-01-01 00:00:00") {
        return "00:00:00";
      } else if (!endDate || endDate == "2000-01-01 00:00:00") {
        endDate = new Date();
      } else {
        endDate = new Date(endDate);
      }
      startDate = new Date(startDate);

      // 计算差值（毫秒）
      var timeDifference = endDate - startDate;

      // 转换差值为HH:mm:ss格式
      var hours = Math.floor(timeDifference / (1000 * 60 * 60));
      var minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // 格式化输出
      var formattedDifference =
        this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);

      return formattedDifference;
    },
    // 在个位数前面补零
    pad(number) {
      return (number < 10 ? "0" : "") + number;
    },
    backup(record) {
      let data = {
        id: record.id,
      };
      api.backup(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("提交成功");
          this.query();
        }
      });
    },
    modifyFullSyncCronTask(record) {
      this.$confirm({
        title: "确认",
        content: "确认是否" + (record.enabled ? "关闭" : "开启"),
        onOk: () => {
          let data = {
            id: record.id,
            status: record.status == "STOP" ? "WAITING" : "STOP",
          };
          api.modifyFullSyncCronTask(data).then((res) => {
            if (res.result === 200) {
              this.$message.success(
                (record.enabled ? "关闭" : "开启") + "成功"
              );
              this.query();
            }
          });
        },
        onCancel() {},
      });
    },
    recover(val, detailVal) {
      this.ids.splice(0);
      if (val) {
        this.taskId = val.id;
      } else if (detailVal) {
        this.ids.push(detailVal.id);
      } else {
        this.ids.push(...this.selectedRowKeys);
      }
      this.databaseInfo = {
        instanceId: this.instanceId,
        databaseName: this.databaseName,
        excludeDatabase: true,
      };

      let data = {
        instanceId: this.instanceId,
      };
      this.getShowDatabases(data);
      this.recoverShow = true;
    },
    recoverSubmit() {
      let data = {
        id: this.taskId,
        ids: this.ids,
        databaseInfo: this.databaseInfo,
      };
      if (this.databaseInfo.instanceId) {
        data.databaseInfo = this.databaseInfo;
      }
      api.recover(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("恢复成功");
          this.recoverShow = false;
          if (this.selectedRowKeys.length > 0) {
            this.detailLog();
            this.selectedRowKeys.splice(0);
            this.selectedRows.splice(0);
          }
        }
      });
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteBackupOperation(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.subtaskList();
        }
      });
    },
    allowClearDetailLogChange(e) {
      if (e.target.value) {
        return;
      }
      this.detailLog(null, 1);
    },
    detailLog(record, index, size) {
      if (record) {
        this.taskId = record.id;
        this.detailConditionTableName = "";
      }
      if (index) {
        this.detailPageNo = index;
      }
      if (size) {
        this.detailPageSize = size;
      }
      let data = {
        taskId: this.taskId,
        pageNo: this.detailPageNo,
        pageSize: this.detailPageSize,
        tableName: this.detailConditionTableName,
      };
      api.backupPlanPage(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          this.detailTableDataSource = list;
          list.forEach((item, index) => {
            item.fileSizeFormat = this.$common.byteConversion(item.fileSize);
            item.timeFormat = this.calculateTimeDifference(
              item.startDate,
              item.endDate
            );
            item.gmtCreatedMilliSeconds = new Date(item.gmtCreated);
            switch (item.status) {
              case "WAITING":
                item.statusLabel = "等待执行";
                item.tagColor = "orange";
                break;
              case "RUNNING":
                item.statusLabel = "运行中";
                item.tagColor = "orange";
                break;
              case "FAILURE":
                item.statusLabel = "失败";
                item.tagColor = "red";
                break;
              case "SUCCESS":
                item.statusLabel = "成功";
                item.tagColor = "green";
                break;
            }
          });

          this.detailTablePagination = {
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current, size) => this.detailLog(null, current, size),
            onShowSizeChange: (current, size) =>
              this.detailLog(null, current, size),
          };
          this.selectedRowKeys.splice(0);
          this.selectedRows.splice(0);
          this.detailShow = true;
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    batchDownloadTable() {
      this.selectedRows.forEach((item) => {
        if (item.status == "SUCCESS") {
          this.downloadTable(item);
        } else {
          this.$message("只有成功状态可以下载");
        }
      });
      this.selectedRowKeys.splice(0);
      this.selectedRows.splice(0);
    },
    downloadTable(record) {
      let url =
        location.protocol +
        process.env.VUE_APP_BASE_API +
        `/backupOperation/downloadTable?id=${record.id}`;

      const a = document.createElement("a");
      a.href = url;
      a.download = ""; // 可选，指定下载文件的名称
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
