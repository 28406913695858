<template>
  <div
    :class="{ operation_panel: operationShow, opreation_close: !operationShow }"
  >
    <div v-show="operationShow">
      <div id="tz" @mousedown="dragEagle"></div>
      <div class="operation_panel_head">
        <div class="head_cz">
          <a-button @click="closeClick" icon="delete" size="small" />
        </div>
      </div>
      <div id="operationPanel" class="operation_panel_info">
        <div class="info_style" v-for="(item, index) in infoList" :key="index">
          <p class="info_item_t">{{ item.date }}</p>
          <p class="info_item_i" v-for="(menu, key) in item.info" :key="key">
            {{ menu }}
          </p>
          <p class="info_item_s">{{ item.millis }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "operationPanel",
  props: ["responseStatement", "operationShow"],
  data() {
    return {
      responseData: [],
    };
  },
  computed: {
    infoList() {
      if (this.responseStatement.info) {
        this.responseData.push(this.responseStatement);
        this.$nextTick(() => {
          let ele = document.getElementById("operationPanel");
          //判断元素是否出现了滚动条
          if (ele.scrollHeight > ele.clientHeight) {
            //设置滚动条到最底部
            ele.scrollTop = ele.scrollHeight;
          }
        });
      }
      return this.responseData;
    },
  },
  mounted() {},
  methods: {
    // 点击关闭
    closeClick() {
      this.responseData.length = 0;
      this.$forceUpdate();
    },
    // 鼠标拖动的宽高变化
    dragEagle(e) {
      var targetDiv = document.getElementById("operationPanel");
      //得到点击时该容器的宽高：
      var targetDivWidth = targetDiv.offsetWidth;
      var targetDivHeight = targetDiv.offsetHeight;
      var startX = e.clientX;
      var startY = e.clientY;

      document.onmousemove = (e) => {
        e.preventDefault();
        //得到鼠标拖动的宽高距离：取绝对值
        var distX = Math.abs(e.clientX - startX);
        var distY = Math.abs(e.clientY - startY);
        //往下方拖动：
        if (e.clientY > startY) {
          targetDiv.style.height = targetDivHeight - distY + "px";
        }
        //往上方拖动：
        if (e.clientY < startY) {
          targetDiv.style.height = targetDivHeight + distY + "px";
        }
        //设置最大最小范围：不能无限制缩放，影响体验
        if (parseInt(targetDiv.style.height) <= 60) {
          targetDiv.style.height = 60 + "px";
        }
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.operation_panel {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  z-index: 999;
  background: #ffffff;
  .operation_panel_head {
    width: 100%;
    height: 30px;
    padding: 5px 5px 0;
    position: relative;
    background-color: #eee;
    .head_cz {
      position: absolute;
      top: 5px;
      right: 15px;
    }
  }
  .operation_panel_info {
    padding: 10px 15px;
    overflow: auto;
    height: 200px;
    width: 100%;
    border-top: 2px solid #dcdfe6;
    .info_style {
      font-size: 12px;
      font-weight: 700;
      margin: 0 0 10px;
      p {
        margin: 0;
        padding: 0;
      }
      .info_item_t {
        color: #67c23a;
      }
      .info_item_i {
        color: #606266;
      }
      .info_item_s {
        color: #909399;
      }
    }
  }
  #tz {
    width: 100%;
    height: 5px;
    border: 0px solid #ededed;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #eee;
    cursor: s-resize;
    z-index: 99;
  }
}
.opreation_close {
  position: fixed;
  bottom: 7px;
  right: 30px;
  z-index: 999;
}
</style>
