import http from "./axios";

// 查询sql审核列表
export const sqlAuditList = (data) => {
  return http.get("/db/sql/sqlAuditList", data);
};

export const userSqlLogProgress = (data) => {
  return http.get("/db/sql/userSqlLogProgress", data);
};

// 添加sql审核列表
export const addSqlAudit = (data) => {
  return http.json_post("/db/sql/addSqlAudit", data);
};
// 审核sql
export const auditSql = (data) => {
  return http.json_post("/db/sql/auditSql", data);
};
// 取消执行
export const cancelSqlPlan = (data) => {
  return http.json_post("/db/sql/cancelSqlPlan", data);
};

export const executeNow = (data) => {
  return http.json_post("/db/sql/executeNow", data);
};

export const loopGenerateSql = (data) => {
  return http.json_post("/db/sql/loopGenerateSql", data);
};

export const idRangeGenerateSql = (data) => {
  return http.json_post("/db/sql/idRangeGenerateSql", data);
};
