<template>
  <div class="tableManagement">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="optimize列表">
        <router-view name="three" v-if="active == '1'" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="表清理">
        <router-view name="three" v-if="active == '2'" />
      </a-tab-pane>
      <a-tab-pane key="3" tab="表转移">
        <router-view name="three" v-if="active == '3'" />
      </a-tab-pane>
      <a-tab-pane key="4" tab="维护日志">
        <router-view name="three" v-if="active == '4'" />
      </a-tab-pane>
      <a-tab-pane key="5" tab="备份">
        <router-view name="three" v-if="active == '5'" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "tableManagement",
  data() {
    return {
      active: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tableManagement/optimize") {
          this.active = "1";
        } else if (newVal == "/tableManagement/databaseTableCleanJob") {
          this.active = "2";
        } else if (newVal == "/tableManagement/databaseTableTransJob") {
          this.active = "3";
        } else if (newVal == "/tableManagement/databaseTablePreserveLog") {
          this.active = "4";
        } else if (newVal == "/tableManagement/backup") {
          this.active = "5";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/tableManagement/optimize") return;
          this.$router.replace("/tableManagement/optimize");
        } else if (newVal === "2") {
          if (this.$route.path == "/tableManagement/databaseTableCleanJob")
            return;
          this.$router.replace("/tableManagement/databaseTableCleanJob");
        } else if (newVal === "3") {
          if (this.$route.path == "/tableManagement/databaseTableTransJob")
            return;
          this.$router.replace("/tableManagement/databaseTableTransJob");
        } else if (newVal === "4") {
          if (this.$route.path == "/tableManagement/databaseTablePreserveLog")
            return;
          this.$router.replace("/tableManagement/databaseTablePreserveLog");
        } else if (newVal === "5") {
          if (this.$route.path == "/tableManagement/backup") return;
          this.$router.replace("/tableManagement/backup");
        }
      },
    },
  },
};
</script>
