<template>
  <div class="whiteIp">
    <div class="searchCondition">
      <div class="searchConditionItem">
        IP地址：
        <a-input
          placeholder="请输入ip地址"
          v-model.trim="ip"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
        <a-button type="primary" @click="addWhiteIp" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      size="small"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-switch
          checkedChildren="允许访问"
          unCheckedChildren="禁止访问"
          v-model="record.allowed"
          @change="allowedChange($event, record)"
          :loading="loading"
        />
      </span>
    </a-table>
    <!-- 新增  -->
    <a-modal
      title="新增"
      v-model="visibleAdd"
      :maskClosable="false"
      :afterClose="closeAdd"
    >
      <a-form>
        <a-form-item
          label="IP地址:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="请输入ip地址" v-model="actionForm.ip" />
        </a-form-item>
        <a-form-item
          label="过期时间:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-select style="width: 240px" v-model="days">
            <a-select-option
              v-for="item in daysSelect"
              :key="item.id"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            v-model="actionForm.remark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addOk"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 允许访问 -->
    <a-modal
      title="允许访问"
      v-model="visibleCall"
      :maskClosable="false"
      :afterClose="closeCall"
    >
      <a-form>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            v-model="callFormRemark"
            placeholder="请输入备注"
            :auto-size="{ minRows: 6, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="callCancel">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="callSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import * as api from "../lib/whiteIp.js";
export default {
  name: "whiteIp",
  data() {
    return {
      visibleCall: false,
      callFormRemark: "",
      columns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "IP地址",
          ellipsis: true,
          dataIndex: "ip",
          // width: 60
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "allowedLabel",
          // width: 60
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          // width: 60
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          // width: 60
        },
        {
          title: "操作者",
          ellipsis: true,
          dataIndex: "operatorName",
          // width: 60
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSource: [],
      pagination: {},
      ip: "",
      visibleAdd: false,
      actionForm: {
        ip: "",
        remark: "",
      },
      loading: false,
      visibleAllowed: false,
      allowedStatus: {
        key: "",
      },
      allowedStatusList: [
        {
          id: 1,
          value: "true",
          label: "允许访问",
        },
        {
          id: 2,
          value: "false",
          label: "禁止访问",
        },
      ],
      userId: "",
      pageNo: 1,
      daysSelect: [
        { id: 1, label: "1天", value: "1" },
        { id: 2, label: "15天", value: "15" },
        { id: 3, label: "一个月", value: "30" },
        { id: 4, label: "永久", value: "" },
      ],
      days: "1",
    };
  },
  mounted() {
    this.getWhiteIp({ pageNo: 1, pageSize: 10 });
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query() {
      let data = {
        pageNo: 1,
        pageSize: 10,
        ip: this.ip,
      };
      this.getWhiteIp(data);
    },
    // 点击新增
    addWhiteIp() {
      this.visibleAdd = true;
    },
    // 确定新增
    addOk() {
      let data = {
        ip: this.actionForm.ip,
        remark: this.actionForm.remark,
        days: this.days,
      };
      if (!data.ip) {
        this.$message.warning("IP地址不能为空");
        return;
      }
      this.loading = true;
      api
        .addWhiteIp(data)
        .then((res) => {
          this.loading = false;
          if (res.result == 200) {
            this.query();
            this.addCancel();
            this.$message.success("新增成功");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 取消新增
    addCancel() {
      this.visibleAdd = false;
    },
    // 关闭新增
    closeAdd() {
      this.actionForm = {
        ip: "",
        remark: "",
      };
    },
    // 查询表格数据
    getWhiteIp(data) {
      api.whiteIpList(data).then((res) => {
        if (res.result == 200) {
          let list = res.data.records;
          list.forEach((item, i) => {
            item.key = i + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            item.gmtModified = this.$common.transformTime(item.gmtModified);
            if (item.gmtCreated === "2000-01-01 00:00:00") {
              item.gmtCreated = "-";
            }
            if (item.gmtModified === "2000-01-01 00:00:00") {
              item.gmtModified = "-";
            }
            if (item.allowed) {
              item.allowedLabel = "允许访问";
            } else {
              item.allowedLabel = "禁止访问";
            }
          });
          this.dataSource = list;
          this.pagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    //分页
    changePageItem(current) {
      this.pageNo = current;
      let data = {
        pageNo: current,
        pageSize: 10,
        ip: this.ip,
      };
      this.getWhiteIp(data);
    },
    // 确认允许访问
    callSubmit() {
      let data = {
        id: this.userId,
        allowed: true,
        remark: this.callFormRemark,
      };
      this.loading = true;
      api
        .changeStatus(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.getWhiteIp({ pageNo: this.pageNo, pageSize: 10 });
            this.$message.success("修改成功");
            this.visibleCall = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 取消允许访问
    callCancel() {
      for (let i in this.dataSource) {
        if (this.dataSource[i].id === this.userId) {
          this.$set(this.dataSource[i], "allowed", false);
        }
      }
      this.visibleCall = false;
    },
    // 关闭允许访问
    closeCall() {
      this.callFormRemark = "";
    },
    // 状态switch
    allowedChange(e, val) {
      let data = {
        id: val.id,
        allowed: e,
      };
      if (e) {
        // 允许访问
        this.userId = val.id;
        this.visibleCall = true;
        this.callFormRemark = val.remark;
      } else {
        api.changeStatus(data).then((res) => {
          if (res.result === 200) {
            this.getWhiteIp({ pageNo: this.pageNo, pageSize: 10 });
            this.$message.success("修改成功");
          }
        });
      }
    },
  },
};
</script>
