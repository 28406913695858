<template>
  <div class="masterSlave">
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columnsSlave)"
      :columns="columnsSlave"
      :dataSource="dataSourceSlave"
      :pagination="paginationSlave"
      size="small"
    >
      <span slot="masterHost" slot-scope="text, record" style="width: 100%">
        <span>{{ text }}</span>
        <a
          @click="syncSlaveClick(record)"
          style="margin-left: 10px; font-size: 12px"
          >刷新</a
        >
      </span>
      <span slot="gmtModified" slot-scope="text, record" style="width: 100%">
        <span :class="record.color ? 'isRed' : ''">{{ text }}</span>
      </span>
      <span slot="sqlDelay" slot-scope="text, record" style="width: 100%">
        <span>{{ text }}</span>
        <a-popconfirm
          placement="right"
          okText="确定"
          cancelText="取消"
          @confirm="batchSubmitConfirm(record)"
          @visibleChange="batchSubmitCancel($event, record)"
        >
          <!-- <a-icon slot="icon" type="" /> -->
          <template slot="title">
            <div class="title-title">设置主动延迟时间</div>
            <div class="form-batchNumber">
              <a-form>
                <a-form-item
                  :label-col="{ span: 4 }"
                  :wrapper-col="{ span: 18 }"
                  label="时间"
                >
                  <a-input
                    v-model="sqlDelay"
                    placeholder="请输入主动延迟时间"
                    size="small"
                    :id="inputData[record.id]"
                  />
                </a-form-item>
              </a-form>
            </div>
          </template>
          <a href="javascript:;" style="margin-left: 10px; font-size: 12px"
            >设置</a
          >
        </a-popconfirm>
      </span>
      <span slot="pos" slot-scope="text, record" style="width: 100%">
        <a-tooltip>
          <template slot="title">
            {{ record.masterLogFile }} , {{ record.relayMasterLogFile }}
          </template>
          <span v-if="text === '-'" color="red">{{ text }}</span>
        </a-tooltip>
        <span v-if="text !== '-'" color="green">{{ text }}</span>
      </span>
      <span slot="slaveIoRunning" slot-scope="text, record" style="width: 100%">
        <a-tooltip>
          <template slot="title">
            [{{ record.lastIoErrorNo }}] {{ record.lastIoError }}
          </template>
          <a-tag v-if="!text" color="red">已停止</a-tag>
        </a-tooltip>
        <a-tag v-if="text" color="green">运行中</a-tag>
      </span>
      <span
        slot="slaveSqlRunning"
        slot-scope="text, record"
        style="width: 100%"
      >
        <a-tooltip>
          <template slot="title">
            [{{ record.lastSqlErrorNo }}] {{ record.lastSqlError }}
          </template>
          <a-tag v-if="!text" color="red">已停止</a-tag>
        </a-tooltip>
        <a-tag v-if="text" color="green">运行中</a-tag>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="slaveDelete(record)"
        >
          <template slot="title">确认是否删除</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "../lib/masterSlave.js";
export default {
  name: "masterSlave",
  data() {
    return {
      pageNo: 1,
      columnsSlave: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 60,
        },
        {
          title: "从库名称",
          ellipsis: true,
          dataIndex: "slaveName",
        },
        {
          title: "主服务器",
          ellipsis: true,
          dataIndex: "masterHostLabel",
          scopedSlots: { customRender: "masterHost" },
        },
        {
          title: "从服务器",
          ellipsis: true,
          dataIndex: "slaveHostLabel",
        },
        {
          title: "同步延迟(秒)",
          ellipsis: true,
          dataIndex: "secondsBehindMaster",
        },
        {
          title: "主动延迟(秒)",
          ellipsis: true,
          dataIndex: "sqlDelay",
          scopedSlots: { customRender: "sqlDelay" },
        },
        {
          title: "slaveIoRunning",
          ellipsis: true,
          dataIndex: "slaveIoRunning",
          scopedSlots: { customRender: "slaveIoRunning" },
        },
        {
          title: "slaveSqlRunning",
          ellipsis: true,
          dataIndex: "slaveSqlRunning",
          scopedSlots: { customRender: "slaveSqlRunning" },
        },
        {
          title: "slaveIoState",
          ellipsis: true,
          dataIndex: "slaveIoState",
        },
        {
          title: "slaveSqlRunningState",
          ellipsis: true,
          dataIndex: "slaveSqlRunningState",
        },
        {
          title: "更新时间",
          ellipsis: true,
          dataIndex: "gmtModified",
          scopedSlots: { customRender: "gmtModified" },
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSourceSlave: [],
      paginationSlave: {},
      sqlDelay: 0,
      inputData: {},
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击删除
    slaveDelete(val) {
      api.slaveDelete({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    // 确定设置主动延迟时间
    batchSubmitConfirm(val) {
      let data = {
        instanceId: val.instanceId,
        sqlDelay: Number(this.sqlDelay),
      };
      api.changeMasterDelay(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("设置成功");
          this.query();
        }
      });
    },
    // 设置主动延迟时间显示or关闭
    batchSubmitCancel(e, val) {
      if (e) {
        this.sqlDelay = val.sqlDelay;
        // 自动获取焦点
        this.$nextTick(() => {
          setTimeout(() => {
            let input = document.getElementById(this.inputData[val.id]);
            input.focus();
          }, 100);
        });
      }
    },
    // 点击刷新
    syncSlaveClick(val) {
      let data = {
        instanceId: val.instanceId,
      };
      this.getSyncSlave(data);
    },
    // 刷新列表数据
    getSyncSlave(data) {
      api.syncSlave(data).then((res) => {
        if (res.result === 200) {
          let data = res.data;
          let actionDate = new Date().getTime();
          data.masterHostLabel = data.masterHost + ":" + data.masterPort;
          data.slaveHostLabel = data.slaveHost + ":" + data.slavePort;
          this.dataSourceSlave.forEach((item, index) => {
            if (item.instanceId === data.instanceId) {
              data.key = item.key;
              if (actionDate - item.gmtModified > 86400000) {
                item.color = true;
              } else {
                item.color = false;
              }
              data.gmtModified = this.$common.transformTime(data.gmtModified);
              if (data.masterLogFile === data.relayMasterLogFile) {
                data.pos = item.readMasterLogPos - data.execMasterLogPos;
              } else {
                data.pos = "-";
              }
              this.$set(this.dataSourceSlave, index, data);
            }
          });
          this.$message.success("刷新成功");
        }
      });
    },
    // 查询主从列表数据
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
      };
      this.getSlaveList(data);
    },
    // 查询主从列表数据
    getSlaveList(data) {
      api.slaveList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let actionDate = new Date().getTime();
          list.forEach((item, index) => {
            item.key = index + 1;
            if (actionDate - item.gmtModified > 86400000) {
              item.color = true;
            } else {
              item.color = false;
            }
            item.gmtModified = this.$common.transformTime(item.gmtModified);
            item.masterHostLabel = item.masterHost + ":" + item.masterPort;
            item.slaveHostLabel = item.slaveHost + ":" + item.slavePort;
            if (item.masterLogFile === item.relayMasterLogFile) {
              item.pos = item.readMasterLogPos - item.execMasterLogPos;
            } else {
              item.pos = "-";
            }
            this.inputData[item.id] = "input" + item.id;
          });
          this.dataSourceSlave = list;
          this.paginationSlave = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 主从列表分页
    changePageItem(index) {
      this.query(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.isRed {
  color: #ff0000;
}
</style>
<style>
.form-batchNumber .ant-form-item {
  margin-bottom: 0px;
}
</style>
