<template>
  <a-modal
    :visible="visible"
    :closable="closable"
    :footer="null"
    @cancel="handleCancel"
    width="300px"
    centered
    class="excessive_loading"
    :maskClosable="false"
  >
    <div class="loading_content">
      <a-spin size="large" />
      <span class="loading_content_text">加载中...</span>
    </div>
  </a-modal>
</template>
<script>
export default {
  name: "loadingAnimation",
  data() {
    return {};
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  methods: {
    // 点击遮罩层或右上角叉或取消按钮的回调
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
};
</script>
<style lang="scss">
.excessive_loading {
  .ant-modal-body {
    .loading_content {
      display: flex;
      justify-content: space-around;
      .loading_content_text {
        line-height: 35px;
        font-size: 16px;
        font-weight: bold;
        width: 125px;
      }
    }
  }
}
</style>
