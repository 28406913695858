<template>
  <div class="accessLog">
    <div class="searchCondition">
      <div class="searchConditionItem">
        IP地址：
        <a-input
          placeholder="请输入ip地址"
          v-model.trim="ip"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select style="width: 240px" v-model="status">
          <a-select-option v-for="item in statusList" :key="item.value">{{
            item.lable
          }}</a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        x：
        <a-input
          placeholder="请输入x"
          v-model.trim="x"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        日期 ：
        <a-date-picker
          :disabledDate="disabledLastLoginStartDate"
          format="YYYY-MM-DD"
          v-model="lastLoginDateStart"
          placeholder="开始日期"
          @openChange="handleLastLoginStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          :disabledDate="disabledLastLoginEndDate"
          format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="lastLoginDateEnd"
          :open="loginEndOpen"
          @openChange="handleLastLoginEndOpenChange"
        />
      </div>
      <div class="searchConditionItem">
        y：
        <a-input
          placeholder="请输入y"
          v-model.trim="y"
          @pressEnter="query"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columns)"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="loading"
      size="small"
    >
      <span slot="isSuccess" slot-scope="text" style="width: 100%">
        <a-tag color="green" v-if="text === '成功'">{{ text }}</a-tag>
        <a-tag color="red" v-if="text === '失败'">{{ text }}</a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
import * as api from "../lib/accessLog.js";
import moment from "moment";

export default {
  name: "accessLog",
  data() {
    return {
      columns: [
        {
          title: "编号",
          // ellipsis: true,
          dataIndex: "key",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "IP地址",
          ellipsis: true,
          dataIndex: "ip",
          // width: 60
        },
        {
          title: "x",
          ellipsis: true,
          dataIndex: "x",
          // width: 60
        },
        {
          title: "y",
          ellipsis: true,
          dataIndex: "y",
          // width: 60
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "isSuccess",
          align: "center",
          scopedSlots: { customRender: "isSuccess" },
        },
        {
          title: "操作",
          ellipsis: true,
          dataIndex: "operation",
          // width: 60
        },
        {
          title: "时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          // width: 60
        },
      ],
      dataSource: [],
      pagination: {},
      loading: false,
      ip: "",
      status: "",
      x: "",
      y: "",
      lastLoginDateStart: null,
      lastLoginDateEnd: null,
      loginEndOpen: false,
      statusList: [
        {
          value: "",
          lable: "全部",
        },
        {
          value: "true",
          lable: "成功",
        },
        {
          value: "false",
          lable: "失败",
        },
      ],
    };
  },
  computed: {
    lastLoginDateEndTime() {
      // 开始时间
      if (this.lastLoginDateEnd) {
        return this.$common.transformTime(this.lastLoginDateEnd);
      }
      return null;
    },
    lastLoginDateStartTime() {
      // 结束时间
      if (this.lastLoginDateStart) {
        return this.$common.transformTime(this.lastLoginDateStart);
      }
      return null;
    },
  },
  mounted() {
    let startTime = new Date();
    startTime.setHours(0);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    let endTime = new Date();
    endTime.setHours(23);
    endTime.setMinutes(59);
    endTime.setSeconds(59);
    endTime.setMilliseconds(59);
    this.lastLoginDateStart = moment(startTime, "YYYY-MM-DD HH:mm:ss");
    this.lastLoginDateEnd = moment(endTime, "YYYY-MM-DD HH:mm:ss");
    this.query();
  },
  methods: {
    moment,
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    // 点击查询
    query() {
      this.loading = true;
      let data = {
        pageNo: 1,
        pageSize: 10,
        ip: this.ip,
        success: this.status,
        x: this.x,
        y: this.y,
        startTime: this.lastLoginDateStartTime,
        endTime: this.lastLoginDateEndTime,
      };
      this.getAccessLog(data);
    },
    // 查询表格数据
    getAccessLog(data) {
      api
        .accessLogList(data)
        .then((res) => {
          if (res.result == 200) {
            let list = res.data.records;
            list.forEach((item, i) => {
              item.key = i + 1;
              item.gmtCreated = this.$common.transformTime(item.gmtCreated);
              item.gmtModified = this.$common.transformTime(item.gmtModified);
              if (item.gmtCreated === "2000-01-01 00:00:00") {
                item.gmtCreated = "-";
              }
              if (item.gmtModified === "2000-01-01 00:00:00") {
                item.gmtModified = "-";
              }
              if (item.success) {
                item.isSuccess = "成功";
              } else {
                item.isSuccess = "失败";
              }
            });
            this.dataSource = list;
            this.pagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    //分页
    changePageItem(current) {
      let data = {
        pageNo: current,
        pageSize: 10,
        ip: this.ip,
        success: this.status,
        x: this.x,
        y: this.y,
        startTime: this.lastLoginDateStartTime,
        endTime: this.lastLoginDateEndTime,
      };
      this.getAccessLog(data);
    },
    // 不可选的登录日期
    disabledLastLoginStartDate(startValue) {
      const endValue = this.lastLoginDateEnd;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledLastLoginEndDate(endValue) {
      const startValue = this.lastLoginDateStart;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    // 选择登录日期
    handleLastLoginStartOpenChange(open) {
      if (!open) {
        this.loginEndOpen = true;
      }
    },
    handleLastLoginEndOpenChange(open) {
      this.loginEndOpen = open;
    },
  },
};
</script>
