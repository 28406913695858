import http from "./axios";

// 查询sql执行列表
export const userSqlLogList = (data) => {
  return http.get("/db/sql/userSqlLogList", data);
};

export const userSqlLogRefresh = (data) => {
  return http.json_post("/db/sql/userSqlLogRefresh", data);
};
