import http from "./axios";

// 查询库列表
export const dataBaseList = (data) => {
  return http.get("/db/instance/dataBaseList", data);
};

// 编辑库列表
export const editDataBaseInfo = (data) => {
  return http.json_post("/db/instance/editDataBaseInfo", data);
};
