<template>
  <div class="passwordManagement">
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="searchForm.name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        x：
        <a-input
          placeholder="请输入x"
          v-model.trim="searchForm.x"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        y：
        <a-input
          placeholder="请输入y"
          v-model.trim="searchForm.y"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        客户端：
        <a-input
          placeholder="请输入客户端"
          v-model.trim="searchForm.client"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchConditionItem">
        生成方式：
        <a-select
          v-model="searchForm.generateType"
          placeholder="请选择生成方式"
          allowClear
          style="width: 240px"
        >
          <a-select-option v-for="i in generateTypeList" :key="i.value">
            {{ i.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addPassword" icon="plus"
          >新增</a-button
        >
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(columnsPassword)"
      :columns="columnsPassword"
      :dataSource="dataSourcePassword"
      :pagination="paginationPassword"
      size="small"
    >
      <span slot="name" slot-scope="text, record" style="width: 100%">
        <div class="host_class">
          <span>{{ text }}</span>
          <a-tooltip v-if="record.locked">
            <template slot="title"> 已锁定 </template>
            <img src="../assets/suo.png" alt="" width="15px" />
          </a-tooltip>
        </div>
      </span>
      <span slot="generateType" slot-scope="text" style="width: 100%">
        <span v-if="text === 'MANUAL'">手动生成</span>
        <span v-if="text === 'API'">接口调用</span>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="modifyAttribute(record)">修改</a>
        <a-divider type="vertical" />
        <a href="javascript:;" @click="getPassword(record)">查看密码</a>
        <a-divider type="vertical" />
        <a
          href="javascript:;"
          @click="modifyPassword(record)"
          :disabled="!record.autoChangeable"
          >重置密码</a
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deletePsw(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="addPasswordShow"
      :maskClosable="false"
      :afterClose="addPasswordClose"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="addPasswordForm.name" />
        </a-form-item>
        <a-form-item
          label="x:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="addPasswordForm.x" />
          <a
            href="javascript:;"
            @click="rootPasswordAdd('x')"
            class="randomPswAdd"
            >随机生成</a
          >
        </a-form-item>
        <a-form-item
          label="y:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="addPasswordForm.y" />
          <a
            href="javascript:;"
            @click="rootPasswordAdd('y')"
            class="randomPswAdd"
            >随机生成</a
          >
        </a-form-item>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="addPasswordForm.password" />
          <a
            href="javascript:;"
            @click="rootPasswordAdd('p')"
            class="randomPswAdd"
            >随机生成</a
          >
        </a-form-item>
        <a-form-item
          label="授权ip:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input placeholder="必填项" v-model="addPasswordForm.allowHost" />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="addPasswordForm.remark"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addPasswordShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="addPasswordOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 修改 -->
    <a-modal
      title="修改"
      v-model="modifyAttributeShow"
      :maskClosable="false"
      :afterClose="modifyAttributeClose"
    >
      <a-form>
        <a-form-item
          label="名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="必填项" v-model="modifyAttributeForm.name" />
        </a-form-item>
        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
          <span slot="label">
            是否锁定
            <a-tooltip>
              <template slot="title"> 锁定后将无法通过api获取密码 </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <a-switch
            checkedChildren="开启"
            unCheckedChildren="关闭"
            v-model="modifyAttributeForm.locked"
          />
        </a-form-item>
        <a-form-item
          label="备注:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="modifyAttributeForm.remark"
            :rows="4"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyAttributeShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modifyAttributeOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 重置密码 -->
    <a-modal
      :title="'重置【' + passName + '】密码'"
      v-model="modifyPasswordShow"
      :maskClosable="false"
      :afterClose="modifyPasswordClose"
    >
      <a-form>
        <a-form-item
          label="新密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input placeholder="必填项" v-model="modifyPasswordForm.password" />
          <a
            href="javascript:;"
            @click="rootPasswordAdd('xp')"
            class="randomPswAdd"
            >随机生成</a
          >
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyPasswordShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modifyPasswordOk"
            >提交</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 查看密码 -->
    <a-modal
      :title="'查看【' + passName + '】密码'"
      v-model="getPasswordShow"
      :maskClosable="false"
      :footer="false"
    >
      <a-form>
        <a-form-item
          label="密码:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="getPasswordForm.password" read-only />
          <a
            href="javascript:;"
            class="copy-class"
            v-clipboard:copy="getPasswordForm.password"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <a-icon type="copy" />
          </a>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/passwordManagement.js";
export default {
  name: "passwordManagement",
  data() {
    return {
      searchForm: {
        x: "",
        y: "",
        name: "",
        client: "",
        generateType: undefined,
      },
      generateTypeList: [
        {
          value: "API",
          label: "接口调用",
        },
        {
          value: "MANUAL",
          label: "手动更新",
        },
      ],
      pageNo: 1,
      columnsPassword: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "客户端",
          ellipsis: true,
          dataIndex: "client",
        },
        {
          title: "x",
          ellipsis: true,
          dataIndex: "x",
        },
        {
          title: "y",
          ellipsis: true,
          dataIndex: "y",
        },
        {
          title: "授权ip",
          ellipsis: true,
          dataIndex: "allowHost",
        },
        {
          title: "生成方式",
          ellipsis: true,
          dataIndex: "generateType",
          scopedSlots: { customRender: "generateType" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataSourcePassword: [],
      paginationPassword: {},
      addPasswordForm: {
        name: "",
        x: this.$common.randomStr(false, 12),
        y: this.$common.randomStr(false, 12),
        password: this.$common.randomStr(false, 12),
        allowHost: "%",
        remark: "",
      },
      addPasswordShow: false,
      loading: false,
      modifyAttributeShow: false,
      modifyAttributeForm: {
        id: "",
        name: "",
        locked: false,
        remark: "",
      },
      modifyPasswordShow: false,
      modifyPasswordForm: {
        id: "",
        password: "",
      },
      getPasswordShow: false,
      passName: "",
      getPasswordForm: {
        password: "",
      },
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击新增
    addPassword() {
      this.addPasswordShow = true;
    },
    // 确定新增
    addPasswordOk() {
      for (let i in this.addPasswordForm) {
        if (i !== "remark" && !this.addPasswordForm[i]) {
          this.$message.warning("信息不完整");
          return;
        }
      }
      this.loading = true;
      api
        .addPassword(this.addPasswordForm)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.addPasswordShow = false;
            this.query(1);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭新增
    addPasswordClose() {
      this.addPasswordForm = {
        name: "",
        x: this.$common.randomStr(false, 12),
        y: this.$common.randomStr(false, 12),
        password: this.$common.randomStr(false, 12),
        allowHost: "%",
        remark: "",
      };
    },
    //随机生成-新增
    rootPasswordAdd(key) {
      let psw = this.$common.randomStr(false, 12);
      switch (key) {
        case "x":
          this.addPasswordForm.x = psw;
          break;
        case "y":
          this.addPasswordForm.y = psw;
          break;
        case "p":
          this.addPasswordForm.password = psw;
          break;
        case "xp":
          this.modifyPasswordForm.password = psw;
          break;
      }
    },
    // 点击修改
    modifyAttribute(val) {
      this.modifyAttributeShow = true;
      this.modifyAttributeForm.id = val.id;
      this.modifyAttributeForm.name = val.name;
      this.modifyAttributeForm.locked = val.locked;
      this.modifyAttributeForm.remark = val.remark;
    },
    // 确定修改
    modifyAttributeOk() {
      if (!this.modifyAttributeForm.name) {
        this.$message.warning("名称不能为空");
        return;
      }
      this.locked = true;
      api
        .modifyAttribute(this.modifyAttributeForm)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.modifyAttributeShow = false;
            this.query();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭修改
    modifyAttributeClose() {
      this.modifyAttributeForm = {
        id: "",
        name: "",
        locked: false,
        remark: "",
      };
    },
    // 点击重置密码
    modifyPassword(val) {
      this.modifyPasswordForm.id = val.id;
      this.modifyPasswordShow = true;
      this.passName = val.name;
    },
    // 确定重置密码
    modifyPasswordOk() {
      this.loading = true;
      api
        .modifyPassword(this.modifyPasswordForm)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("密码修改成功");
            this.modifyPasswordShow = false;
            this.query();
          }
        })
        .catch((err) => {
          this.loading = false;
          // this.$message.error(err.response.data);
        });
    },
    // 关闭重置密码
    modifyPasswordClose() {
      this.modifyPasswordForm = {
        id: "",
        password: "",
      };
      this.passName = "";
    },
    // 点击查看密码
    getPassword(val) {
      api
        .getPassword({ id: val.id })
        .then((res) => {
          if (res.result === 200) {
            this.getPasswordShow = true;
            this.passName = val.name;
            this.getPasswordForm.password = res.data;
          }
        })
        .catch((err) => {
          // this.$message.error(err.response.data);
        });
    },
    // 复制成功
    onCopy(e) {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError(e) {
      this.$message.error("复制失败");
    },
    // 点击删除
    deletePsw(val) {
      api.deletePassword({ id: val.id }).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询主从列表数据
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.searchForm.name,
        x: this.searchForm.x,
        y: this.searchForm.y,
        client: this.searchForm.client,
        generateType: this.searchForm.generateType,
      };
      this.getPasswordListList(data);
    },
    // 查询主从列表数据
    getPasswordListList(data) {
      api.passwordList(data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.dataSourcePassword = list;
          this.paginationPassword = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.changePageItem(current),
          };
        }
      });
    },
    // 主从列表分页
    changePageItem(index) {
      this.query(index);
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-class {
  position: absolute;
  top: -9px;
  right: 13px;
  font-size: 18px;
  z-index: 3;
}
.randomPswAdd {
  position: absolute;
  right: -66px;
  top: -10px;
}
.host_class {
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
  }
}
</style>
