import http from "./axios";

// 查询表格数据
export const slaveList = (data) => {
  return http.get("/db/slave/list", data);
};

// 刷新表格数据
export const syncSlave = (data) => {
  return http.get("/db/slave/syncSlave", data);
};

// 设置主动延迟时间
export const changeMasterDelay = (data) => {
  return http.json_post("/db/slave/changeMasterDelay", data);
};

// 删除
export const slaveDelete = (data) => {
  return http.json_post("/db/slave/delete", data);
};
